import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/Axios';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
 
// -------------------------Update Career Request-------------------------//
const careerStatusHandler = async (payload) => {
    const { data } = await axiosInstance.put(`/admin/career/status/${payload?.id}`, payload.data);

    return data.data
}

export const useCareerStatusHandler = () => {
    const { showToast, setDisableBtn } = useAdminGlobalContext();
  
    return useMutation(careerStatusHandler, {
        onSuccess: async (data) => {
            setDisableBtn(false)
            showToast("success", "Career Request", "Career Request Updated Successfully!")
         },
        onError: (error) => {
            setDisableBtn(false)
            showToast("error", "Career Request", error?.response?.data?.message)
        }
    })
}

// -------------------------Get Career By Id-------------------------//
const getCareerById = async (Id) => {
    const { data } = await axiosInstance.get(`/career/show/${Id}`);

    return data.data
}

export const useGetCareerById = () => {
    const { showToast, setDisableBtn } = useAdminGlobalContext();
  
    return useMutation(getCareerById, {
        onSuccess: async (data) => {
            setDisableBtn(false)
         },
        onError: (error) => {
            setDisableBtn(false)
            showToast("error", "Career Details", error?.response?.data?.message)
        }
    })
}
