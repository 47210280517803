import React from 'react'
import { useTranslation } from 'react-i18next';

import { Image } from 'react-bootstrap'

import checkImage from "../../../../assets/img/check.png"
import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';

function Step7 ({ reset }) {
  const { t } = useTranslation("vendor");
  const { activeStep, setActiveStep } = useClientGlobalContext();

  const goBack = () => {
    reset()
    setActiveStep(0)
  }

  return (
    <div className=' h-[100vh] d-flex justify-center items-center w-100' >
      <div className='career-form-container effect-3d-shadow rounded-[40px] w-[100%] md:w-[60%] flex flex-col items-center p-5 justify-center' style={ { height: "calc(100vh - 180px)" } }>
        <Image src={ checkImage } width="100" />
        <div className="h1 my-5 text-capitalize text-center">{ t("step7.title") }</div>
        <button className="h3 my-3 bg-white text-black px-5 py-3 rounded-[20px]" onClick={ () => goBack() }>{ t("step7.btn") }</button>
      </div>
    </div>
  )
}

export default Step7