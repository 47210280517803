import React, { useEffect, useState, useRef } from "react";
import Video from "../../../../assets/img/video.mp4";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import headerAnimation from "../../../../assets/img/animation/1- Header/1- Header/2/2.gif";
import headerAnimation2 from "../../../../assets/img/animation/1- Header/explore.gif";
import headerAnimation3 from "../../../../assets/img/animation/1- Header/1- Header/3/3.gif";
import { useGetVideo } from "../../Queries/event";
import { useLocation } from 'react-router-dom';

function Headers() {
  const { t } = useTranslation("home");
  const [activeAnimation, setActiveAnimation] = useState(0);
  const animations = [headerAnimation, headerAnimation2, headerAnimation3];
  const { data: getVideo } = useGetVideo();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveAnimation((prev) => (prev + 1) % animations.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [animations.length]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [location]);



  return (
    <div
      className="flex flex-col items-center justify-center video-wrapper relative w-full "
      data-aos="fade-down"
    >
      {getVideo &&
        Array.isArray(getVideo) &&
        getVideo.length > 0 &&
        getVideo.map((video, index) => (
          <video
            key={index}
            autoPlay
            playsInline
            loop
            muted
            className="object-cover "
            style={{
              width: "100%",
            }}
          >
            <source
              src={isMobile ? video.home_mobile_video : video.home_video}
              type="video/mp4"
            />{" "}
          </video>
        ))}
      {/* headers_container */}
      <div
        className="  z-[1000] w-[88%] lg:w-[80%] xl:w-[60%] rounded-[31.64px] text-center flex flex-col justify-center items-center  my-[5vh]  "
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div
          style={{ background: "#0000004D", border: "1px solid #6E6E6E" }}
          className="w-[100%] p-[20px] rounded-[31.64px]"
        >
          <div className="mb-3">
            <span className="w-[90%] font-[800] text-[130%] lg:text-[280%] md:text-[200%] text-center">
              {t("headers.title")}
            </span>
            <span className="font-[800] text-[130%] lg:text-[280%] md:text-[200%] text-yellow">
              {t("headers.ksa")}
            </span>
          </div>
          <p className="font-light lg:text-[22px] md:text-[14px] mb-10 w-[90%] lg:w-[60%] mx-auto">
            LuxuryKSA {t("headers.description")}
          </p>
          <Link to="/about-us">
            <button className="about-us-btn butt lg:text-[22px] rounded-full font-semibold border-2 p-3">
              {t("headers.about_luxury")}
            </button>
          </Link>
        </div>
      </div>

      {/* {activeAnimation === 0 && (
          <div className=" absolute w-[35%] left-[55%] bottom-[10%] hide-on-tablet">
            <img className="w-[%]" src={headerAnimation2} alt="Animation 2" />
          </div>
        )}
        {activeAnimation === 1 && (
          <div className=" absolute left-0 top-[30%] hide-on-tablet">
            <img src={headerAnimation} alt="Animation 2" />
          </div>
        )}
        {activeAnimation === 2 && (
          <div className=" absolute xl:right-[36%] lg:right-[30%] bottom-0 hide-on-tablet">
            <img
              className="w-[30rem]"
              src={headerAnimation3}
              alt="Animation 2"
            />
          </div>
        )} */}
    </div>
  );
}

export default Headers;
