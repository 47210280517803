import React, { useState, useEffect, useRef, useContext, createContext, useMemo } from 'react';
import axiosInstance from '../../../config/Axios';

const ScrollLoading = createContext({});

export const ScrollLoadingProvider = (props) => {
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading]   = useState(true);
    const [pageNum, setPageNum]   = useState(1);
    const [apiBody,setApiBody]    = useState({})
    const [data, setData] = useState([]);
    const [path, setPath] = useState("");
    const [ids, setIds] = useState([]);
    
    const [apiConfig, setApiConfig] = useState({
        path: "",
        body: {}
    })

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                 setPageNum((no) => no + 1);
            }
        })
    );

    const callMedia = async () => {
        setLoading(true);
        let response = await axiosInstance.post(`${ apiConfig?.path }?page=${ pageNum }&per_page=10`, apiConfig?.body);
        let all = pageNum > 1 ? new Set([...data, ...response.data.data]) : new Set([...response.data.data]);
        setData([...all]);
        setLastPage(response.data.pagination.total_pages);
        setLoading(false);
    };

    useMemo(() => {
        if (apiConfig?.path !== '') {
            callMedia();
        }
    }, [apiConfig]);

    useEffect(() => {
        if ((pageNum <= lastPage || lastPage == null) && pageNum > 1) {
            callMedia();
        }
    }, [pageNum]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    return (
        <ScrollLoading.Provider value={ {
            data, setData,
            ids, setIds,
            apiBody,setApiBody,
            lastElement, setLastElement,

            lastPage, setLastPage,
            loading, setLoading,
            pageNum, setPageNum,

            setPath,
            callMedia,

            apiConfig, setApiConfig
        } }>
            { props.children }
        </ScrollLoading.Provider>
    )

}

export const useScrollLoadingContext = () => {
    return useContext(ScrollLoading);
}
