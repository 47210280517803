import React  from "react";
import Menu from "./Menu";
import Profile from "./Profile";
import Footer from "./Footer";
import { Navigate } from "react-router-dom";
 
function Layout (props) {
    const token = localStorage.getItem("token");

    if (!token) {
        return <Navigate to="/admin" />;
    }  

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Profile />
                    <div className="content-wrapper">
                        <div className="mx-3 mx-md-3 px-2 pt-2 pb-2 flex-grow-1 container-p-y">
                            { props.children }
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
         </div>
    );
}
export default Layout;