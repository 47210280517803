import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import ReactGA from 'react-ga4';
import eventsImage from "../../../../assets/img/featured_event.png";
import example from "../../../../assets/img/example.png";
import featuredAnimation from "../../../../assets/img/animation/featured_Events/3- Featured Events/Dance/Dance.gif";
import featuredAnimation2 from "../../../../assets/img/animation/featured_Events/3- Featured Events/Sing/Sing.gif";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useGetEventList } from "../../Queries/event";
import moment from "moment";
import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import DialogBoxOffece from "../../BoxOffice/components/DialogBoxOffecee";
const _events = [
    {
        name: "Event Name",
        image: example,
        date: "dd-mm-yyyy",
        location: "Event Location ",
    },
    {
        name: "Event Name",
        image: example,
        date: "dd-mm-yyyy",
        location: "Event Location ",
    },
];
export default function FeaturedEvents() {
    const { t, i18n } = useTranslation("home");
    const { data: eventList } = useGetEventList();

    return (
        <div
            className="d-flex px-md-4 mx-3 mx-lg-5 my-5"
        // style={ { minHeight: "650px" } }
        >
            <div className="d-none d-md-block col-md-4 col-lg-5 my-auto relative pe-3 ">
                <Image src={eventsImage} />
                <div className="absolute xl:w-[45%] xl:left-[22%] xl lg:top-[60%] lg:left-[20%] lg:w-[60%] md:left-[20%] md:w-[60%] md:top-[50%] hide-on-tablet ">
                    <img src={featuredAnimation} alt="" />
                </div>
                <div className="absolute xl:w-[40%] xl:top-[45%] xl:left-[30%] lg:top-[45.5%] lg:w-[50%] lg:left-[31%] md:left-[25%] hide-on-tablet ">
                    <img src={featuredAnimation2} alt="" />
                </div>
            </div>

            <div className="col-12 col-md-8 col-lg-7 d-flex flex-column my-auto text-center">
                <div className="col-12 fs-2 fw-bold mb-4">
                    {t("featured_event.title")}
                    <span className="text-yellow ">
                        {t("featured_event.title-event")}
                    </span>
                </div>
                <div className="col-12 d-flex md:justify-evenly flex-wrap">
                    {eventList?.map((item, index) => {
                        return index < 2 ? (
                            <EventCard
                                item={item}
                                key={index}
                                index={index}
                                eventListLength={eventList?.length}
                            />
                        ) : (
                            <></>
                        );
                    })}
                </div>

                <div className="col-12 h4 text-uppercase mt-4 fw-semibold d-flex justify-content-end align-items-center">
                    <NavLink to="/box-offices">{t("featured_event.go_to_box")}</NavLink>
                    <div className="bg-white rounded-circle p-1 ms-3">
                        <div className="bg-black go-to-box-btn rounded-circle px-2 py-1">
                            <i
                                className={`fa-solid fa-arrow-${i18n.language === "en" ? "right" : "left"
                                    } text-white`}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const EventCard = ({ item, widthClass = "col-sm-6" }) => {
    const { t, i18n } = useTranslation("home");
    const { setEventDetails } = useClientGlobalContext();
    const handleButtonClick = () => {
        // Set event details
        setEventDetails(item);

        // Track the event with Google Analytics
        ReactGA.event({
            category: 'Client',
            action: 'Book_event',
            label: `Event: ${item?.name_en} ${item?.start_date}`
        });
    };
    return (
        <div className={`col-12 ${widthClass} mb-3 px-2`}>
            <div className={`event_container`}>
                <div className="image_container ">
                    <div className="relative w-full h-full aspect-ratio-box event_image">
                        <img
                            src={item?.poster}
                            alt=""
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
                <div className="px-[0] details_container">
                    <div className="ms-2 pb-3 px-4 md:pb-3 lg:p-4">
                        <h3 className="lg:text-2xl text-lg fw-bold text-capitalize mb-3">
                            {i18n.language === "ar" ? item?.name_ar : item?.name_en}
                        </h3>
                        <p className="mb-1">
                            <i className="fas fa-calendar-alt me-2"></i>
                            {moment(item?.start_date).format("YYYY/MM/DD")}
                            {/* -{" "}
{moment(item?.end_date).format("YYYY/MM/DD")} */}
                        </p>
                        <p>
                            <i className="fas fa-map-marker-alt me-2"></i>
                            {item?.location}
                        </p>
                    </div>
                    {/* <DialogBoxOffece item={item} /> */}
                    <Link
                        to={
                            item?.start_date === "2024-07-26"
                                ? "https://malaga.platinumlist.net/event-tickets/yallah-habibi"
                                : item.start_date === "2024-07-27"
                                    ? "https://malaga.platinumlist.net/event-tickets/92911/yallah-habibi"
                                    : `/box-office/payment/${item?.id}`
                        }
                    >
                        <button
                            onClick={handleButtonClick}
                            className="w-100 bg-[#EEFF29] h4 fw-bold text-black py-[10px] lg:py-[20px] mb-0 "
                        >
                            {t("featured_event.book_btn")}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

