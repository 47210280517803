import React from 'react';

import Menu from "../Layout/components/Menu";
import Footer from './components/Footer';

function Layout (props) {
  return (
    <div className="landingBody bg-black" >
      <div className={ `content` }>
        <Menu />
        <div className="w-100 d-flex flex-column">
          { props.children }
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
