import React, { Suspense, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from 'react-ga';
import "./assets/vendor/css/boxicons/boxicons.css";
import "./assets/vendor/css/fontawesome/fontawesome.css";
import "primereact/resources/themes/arya-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "./config/i18n";

import { ScrollLoadingProvider } from "./pages/Admin/Contexts/ScrollLoadingContext";
import { ClientGlobalProvider } from "./pages/Client/Contexts/ClientGlobalContext";
import { AdminGlobalProvider } from "./pages/Admin/Contexts/AdminGlobalContext";
import { DataTableProvider } from "./pages/Admin/Contexts/DataTableContext";
 
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
 import Setting from "./pages/Admin/Setting/Setting"
import { ProgressSpinner } from "primereact/progressspinner";
import BoxOffices from "./pages/Client/BoxOffice/BoxOffices";
import Landing from "./pages/Client/Landing/Landing";
import Careers from "./pages/Client/Careers/Careers";
import Packages from "./pages/Client/Packages/Packages";
import XArtist from "./pages/Client/X-Artist/X-Artist";
import AboutUs from "./pages/Client/AboutUs/AboutUs";
import Vendor from "./pages/Client/Vendor/Vendor";
import Login from "./pages/Auth/Login";

import BoxOfficePayment from "./pages/Client/BoxOffice/BoxOfficePayment";
import { I18nextProvider, useTranslation } from "react-i18next";
import EventsIndex from "./pages/Admin/Events/EventsIndex";
import Ticket from "./pages/Client/Ticket";
import NotFound from "./pages/Client/NotFound";
import VendorIndex from "./pages/Admin/Vendors/VendorIndex";
import CareersIndex from "./pages/Admin/Careers/CareersIndex";
import VendorDetails from "./pages/Admin/Vendors/components/VendorDetails";
import EventSchedule from "./pages/Admin/Events/EventSchedule";
import EventScheduleUsers from "./pages/Admin/Events/EventScheduleUsers";
import CareerDetails from "./pages/Admin/Careers/components/CareerDetails";

ReactGA.initialize('G-P134020H2N');

const queryClient = new QueryClient();

export const Loading = () => {
  return (
    <div className="flex justify-center items-center h-100">
      <ProgressSpinner
        className="w-[60px] height-[60px] opacity-10"
        strokeWidth="3"
        fill="var(--surface-ground)"
        animationDuration="1s"
      />
    </div>
  );
};

export default function App () {
  const { i18n } = useTranslation();
  const [cacheBuster, setCacheBuster] = useState(Date.now());
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setCacheBuster(Date.now())
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";

    const loadStyle = () => {
      const lang = i18n.language === "ar" ? "ar" : "en";
      const styleSheet = document.createElement("link");
      styleSheet.href = `./css/${ lang }/index-${ lang }.css?v=${ cacheBuster }`;
      styleSheet.rel = "stylesheet";
      styleSheet.onload = () => {
        setDidMount(true);
      };
      document.head.appendChild(styleSheet);
    };

    loadStyle();
  }, [i18n.language]);

  return (
    <QueryClientProvider client={ queryClient }>
      <I18nextProvider i18n={ i18n }>
        <BrowserRouter>
          {/* Admin Routes */ }
          <AdminGlobalProvider>
            <DataTableProvider>
              <ScrollLoadingProvider>
                <Routes>
                  <Route group="admin" >
                    <Route path="/admin" element={ <Login /> } />
                    <Route path="/admin/dashboard" element={ <Dashboard /> } />

                    <Route path="/admin/events" element={ <EventsIndex /> } />
                    <Route path="/admin/event/schedule/:id" element={ <EventSchedule /> } />
                    <Route path="/admin/event/schedule/:id/users" element={ <EventScheduleUsers /> } />

                    <Route path="/admin/careers" element={ <CareersIndex /> } />
                    <Route path="/admin/careers/:id/details" element={ <CareerDetails /> } />

                    <Route path="/admin/vendors" element={ <VendorIndex /> } />
                    <Route path="/admin/vendors/:id/details" element={ <VendorDetails /> } />

                    <Route path="/admin/setting" element={ <Setting /> } />
                  </Route>
                </Routes>
              </ScrollLoadingProvider>
            </DataTableProvider>
          </AdminGlobalProvider>

          {/* Clients Routes Routes */ }
          <ClientGlobalProvider>
            <Routes>
              <Route path="/" element={ <Landing /> } />
              <Route path="/vendor" element={ <Vendor /> } />
              <Route path="/box-offices" element={ <BoxOffices /> } />
              <Route path="/careers" element={ <Careers /> } />
              <Route path="/packages" element={ <Packages /> } />
              <Route path="/x-artist" element={ <XArtist /> } />
              <Route path="/about-us" element={ <AboutUs /> } />
              <Route path="/event/ticket" element={ <Ticket /> } />
              <Route path="/box-office/payment/:id" element={ <BoxOfficePayment /> } />

              { !window.location.pathname.includes("admin") ? <Route path="*" element={ <NotFound /> } /> : <></> }

            </Routes>
          </ClientGlobalProvider>
        </BrowserRouter>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={ <Loading /> }>
    <App />
  </Suspense>
);
