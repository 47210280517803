import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import { getFormErrorMessage } from "../../../../config/global";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Container } from "react-bootstrap";
import { isString } from "lodash";

function Step2({ errors, control, setValue }) {
  const { t, i18n } = useTranslation("box_offices");
  const { setActiveStep, activeStep } = useClientGlobalContext();

  const validateStepData = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <>
     <div className="col-12 col-md-12  ps-2">
        <div className="col-span-2 sm:col-span-1">
          <label
            htmlFor="givenName-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.givenName")}
          </label>
          <Controller
            name="givenName-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "givenName input is required."
                    : false
                  : activeStep === 1
                  ? "الاسم المعطى  مطلوب"
                  : false,
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("givenName-input", errors)}
        </div>
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="surname-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.surname")}
          </label>
          <Controller
            name="surname-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "surname input is required."
                    : false
                  : activeStep === 1
                  ? "اسم العائلة  مطلوب"
                  : false,
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("surname-input", errors)}
        </div>
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="phone-number-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.Phone-Number")}
          </label>
          <Controller
            name="phone"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "phone number  input is required."
                    : false
                  : activeStep === 1
                  ? "رقم الهاتف مطلوب"
                  : false,
                  validate: {
                    length: (value) =>
                      value.length <= 20 || (i18n.language === "en" ? "Maximum length is 20 characters." : "الحد الأقصى للطول هو 20 حرفًا."),
                    format: (value) =>
                      /^(\+?\d{1,3}-)?\d{1,15}$/.test(value) || (i18n.language === "en" ? "Invalid phone number format." : "تنسيق رقم الهاتف غير صالح."),
                  },
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="number"
                placeholder="+123-456789012345"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("phone", errors)}
        </div>
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="email-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.Email-Address")}
          </label>
          <Controller
            name="email"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "Email is required."
                    : false
                  : activeStep === 1
                  ? "البريد الاكتروني مطلوب"
                  : false,
                  maxLength: {
                    value: 128,
                    message: i18n.language === "en" ? "Email must be at most 128 characters." : "يجب أن يكون البريد الإلكتروني على الأكثر 128 حرفًا.",
                  },
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: i18n.language === "en" ? "Please enter a valid email address." : "يرجى إدخال عنوان بريد إلكتروني صحيح.",
                  },
                
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="email"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("email", errors)}
        </div>
        
        {/* <div className="flex flex-row justify-start items-start text-xl my-3">
          <span className="mr-10"> { t("second-page.Total") } </span>
          <span>{ t("second-page.price") }</span>
        </div> */}
         <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="billing-postcode-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.billing-postcode")}
          </label>
          <Controller
            name="billing-postcode-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "billing postcode input is required."
                    : false
                  : activeStep === 1
                  ? "الرمز البريدي  مطلوب"
                  : false,
                  validate: {
                    length: (value) =>
                      value.length <= 50 || (i18n.language === "en" ? "Maximum length is 50 characters." : "الحد الأقصى للطول هو 50 حرفًا."),
                    isString: (value) =>
                      typeof value === 'string' || (i18n.language === "en" ? "Invalid format." : "تنسيق غير صالح."),
                  },
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("billing-postcode-input", errors)}
        </div>
      </div>
      <div className="col-12 col-md-12   ps-2">
        <div className="col-span-2 sm:col-span-1">
          <label
            htmlFor="billing-street1-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.billing-street1")}
          </label>
          <Controller
            name="billing-street1-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "billing street1 input is required."
                    : false
                  : activeStep === 1
                  ? "اسم الشارع مطلوب"
                  : false,
                  validate:{
                    maxLength:(value)=>
                      value.length <=50 || (i18n.language==="en" ? "Maximum length is 50 characters." : "الحد الأقصى للطول هو 50 حرفًا."),
                    isString:(value)=>
                      typeof value==='string' || (i18n.language==="en" ? "Invalid format" : "التنسيق غير صالح")
                  }
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("billing-street1-input", errors)}
        </div>{" "}
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="billing-city-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.billing-city")}
          </label>
          <Controller
            name="billing-city-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "billing city input is required."
                    : false
                  : activeStep === 1
                  ? "اسم المدينة مطلوب"
                  : false,
                  validate: {
                    length: (value) =>
                      value.length >= 2 && value.length <= 45 || (i18n.language === "en" ? "Length must be between 2 and 45 characters." : "يجب أن يتراوح الطول بين 2 و 45 حرفًا."),
                    isString: (value) =>
                      typeof value === 'string' || (i18n.language === "en" ? "Invalid format." : "تنسيق غير صالح."),
                  },
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("billing-city-input", errors)}
        </div>{" "}
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="billing-state-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.billing-state")}
          </label>
          <Controller
            name="billing-state-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "billing state input is required."
                    : false
                  : activeStep === 1
                  ? "اسم الحالة مطلوب"
                  : false,
                  validate: {
                    length: (value) =>
                      value.length <= 3 || (i18n.language === "en" ? "Maximum length is 3 characters." : "الحد الأقصى للطول هو 3 أحرف."),
                    format: (value) =>
                      /^[A-Z0-9-]*$/.test(value) || (i18n.language === "en" ? "Invalid format." : "تنسيق غير صالح."),
                  },
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("billing-state-input", errors)}
        </div>
        <div className="col-span-2 sm:col-span-1 mt-4">
          <label
            htmlFor="full-name-input"
            className="mb-2 block text-base text-gray-900 dark:text-white"
          >
            {t("teard-page.billing-country")}
          </label>
          <Controller
            name="billing-country-input"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 1
                    ? "billing country input is required."
                    : false
                  : activeStep === 1
                  ? "اسم المدينة مطلوب"
                  : false,
                  validate: {
                    length: (value) =>
                      value.length === 2 || (i18n.language === "en" ? "Must be exactly 2 characters." : "يجب أن يكون بالضبط 2 أحرف."),
                    format: (value) =>
                      /^[A-Z]{2}$/.test(value) || (i18n.language === "en" ? "Invalid country code format." : "تنسيق رمز الدولة غير صالح."),
                  },
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                inputRef={field.ref}
                placeholder="US"
                type="text"
                className={`input-3d-shadow bg-gradient-to-r from-[#ffffff31] to-black rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("billing-country-input", errors)}
        </div>
      </div>
    </>
  );
}

export default Step2;
