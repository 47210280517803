import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/Axios';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
import { useDataTableContext } from '../Contexts/DataTableContext';


// -------------------------Create Event API-------------------------//
const createEvent = async (payload) => {
    const { data } = await axiosInstance.post("/event/create", payload);

    return data.data;
}

export const useCreateEventMutation = () => {
    const { showToast, dialogHandler } = useAdminGlobalContext();
    const { setLazyParams } = useDataTableContext();
 
    return useMutation(createEvent, {
        onSuccess: (data) => {
            dialogHandler("createEvent")
            setLazyParams(prev => ({
                ...prev,
                modelName: "events",
                filters: {
                    'name_ar': { value: '', matchMode: 'contains' },
                    'name_en': { value: '', matchMode: 'contains' },
                 }
            }))
            showToast("success", "Event Creation", "Event created successfully!")
        },
        onError: (error) => showToast("error", "Event Creation", error.response?.data?.message)
    })
}


// -------------------------Edit Event API-------------------------//
const editEvent = async (payload) => {
    const { data } = await axiosInstance.post("/event/update", payload);

    return data.data;
}

export const useEditEventMutation = () => {
    const { showToast, dialogHandler } = useAdminGlobalContext();
    const { setLazyParams } = useDataTableContext();
 
    return useMutation(editEvent, {
        onSuccess: (data) => {
            dialogHandler("editEvent")
            setLazyParams(prev => ({
                ...prev,
                modelName: "events",
                filters: {
                    'name_ar': { value: '', matchMode: 'contains' },
                    'name_en': { value: '', matchMode: 'contains' },
                 }
            }))
            showToast("success", "Event Editing", "Event updated successfully!")
        },
        onError: (error) => showToast("error", "Event Editing", error.response?.data?.message)
    })
}

