import { useMutation, useQuery } from 'react-query';

import { useClientGlobalContext } from '../Contexts/ClientGlobalContext';
import axiosInstance from '../../../config/Axios';

// -------------------------Create Career API-------------------------//
const vendorRegistration = async (payload) => {
    const { data } = await axiosInstance.post("/vendor/create", payload);

    return data.data;
}

export const useVendorRegistrationMutation = () => {
    const { showToast } = useClientGlobalContext();

    return useMutation(vendorRegistration, {
        onSuccess: (data) => showToast("success", "Vendor Registration", data?.msg),
        onError: (error) => showToast("error", "Vendor Registration", error.response?.data?.message)
    })
}