import { useMutation, useQuery } from "react-query";
import { forEach, isEmpty } from "lodash";

import { useClientGlobalContext } from "../Contexts/ClientGlobalContext";
import axiosInstance from "../../../config/Axios";

// -------------------------Create Career API-------------------------//
const getTicketInfo = async (payload) => {
  const { data } = await axiosInstance.get(`/event/getTicket/${payload?.id}`);

  return data.data;
};

export const useGetTicketMutation = () => {
  const { showToast } = useClientGlobalContext();

  return useMutation(getTicketInfo, {
    onError: (error) =>
      showToast("error", "Ticket", error.response?.data?.message),
  });
};

// -------------------------Get Event API-------------------------//

const getEventList = async () => {
  const { data } = await axiosInstance.get("/event/list");
  return data.data;
};

export const useGetEventList = () => {
  const { showToast } = useClientGlobalContext();

  let { isLoading, data } = useQuery("eventsList", getEventList, {
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while booking the event";
      showToast("error", "Event List Error", errorMessage);
    },
  });
  return { isLoading, data };
};

// -------------------------Book Event API-------------------------//
const bookEvents = async (payload) => {
  const formData = new FormData();
  forEach(payload, (val, key) => {
    if (typeof val === "string" && isEmpty(val)) return;
    formData.append(key, val);
  });
  const { data } = await axiosInstance.post("/event/book", formData);
  return data;
};

export const useBookEvents = () => {
  const { showToast } = useClientGlobalContext();

  return useMutation(bookEvents, {
    onSuccess: () => {
      showToast("success", "BookEvents added successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while booking the event";
      showToast("error", "Booking Event Error", errorMessage);
    },
  });
};

const getVideo = async () => {
  const { data } = await axiosInstance.get("/setting/get");
  return data.data;
};
export const useGetVideo = () => {
  const { showToast } = useClientGlobalContext();

  let { isLoading, data, error, isError } = useQuery("getVideo", getVideo);

  if (isError) {
    showToast("error", "Fetching data", error.response?.data?.message);
  }

  return { isLoading, data };
};


//get event by id///
const getEventById = async (id) => {
  const { data } = await axiosInstance.get(`/event/show/${id}`);
  return data.data;
};
export const useGetEventById = (id) => {
  const { showToast } = useClientGlobalContext();

  let { isLoading, data, error, isError } = useQuery("getEvent",()=> getEventById(id));

  if (isError) {
    showToast("error", "Fetching data", error.response?.data?.message);
  }

  return { isLoading, data };
};




