import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import animation from '../../../../assets/img/animation/work_with_us/5- Work with us/Idea.gif'

function WorkWithUs () {
    const { t } = useTranslation("home");

    return (
        <div className='px-md-4 py-5 mx-3 mx-lg-5 md:my-5 relative flex flex-col justify-center items-center '>
            <div className='rounded-[40px] bg-[#ffffffe6] w-[82%]     py-4  md:py-5 d-flex flex-column  align-items-center justify-center'>
      
                <h1 className='font-[1000] h4 md:h1 text-uppercase text-black mb-4 md:mb-3'> { t('work_with_us.title') }</h1>
                <NavLink to={"/careers"} >
                    <button className='submit_btn bg-black text-white py-3 px-3 md:py-3  md:px-5 rounded-[20px] fw-bold'> {t('work_with_us.apply')}</button>
                </NavLink>
            </div>
            <div className="absolute xl:w-[8%] md:w-[12%] right-[0%]  hide-on-tablet "><img src={animation} alt="" /></div>
        </div>
    )
}

export default WorkWithUs;
