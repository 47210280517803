import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../Contexts/DataTableContext';
import { useTitle } from '../../../config/global';
import Layout from '../Layout/Layout';


function CareersIndex () {
    useTitle('events');

    const { totalRecords, onPage, lazyParams, setLazyParams, data, dataHandler, getDataTable } = useDataTableContext();
    const [selectedEvent, setSelectedEvent] = useState({ name_en: "" });

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "careers",
            filters: {
                'fullName': { value: '', matchMode: 'contains' },
                'email': { value: '', matchMode: 'contains' },
                'position': { value: '', matchMode: 'contains' },
                'degree': { value: '', matchMode: 'contains' },
                'career_type': { value: '', matchMode: 'contains' },
                'experience': { value: '', matchMode: 'contains' },
                'package': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Link to setup page */ }
                {/* <Tooltip target={ `.setup-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon setup-button-${ rowData.id }` } onClick={ () => goToSetup(rowData.id) } data-pr-position="bottom" data-pr-tooltip="Setup"><i className='bx bx-slideshow' ></i></button> */}

                {/* Update event */ }
                {/* <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => editEvent(rowData) }><i className="pi pi-pencil"></i></button> */}
            </div>
        );
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2 fs-4">Events List</h5>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable
                        lazy
                        paginator
                        rows={ lazyParams.rows }
                        value={ data }
                        first={ lazyParams.first }
                        onPage={ onPage }
                        onSort={ dataHandler }
                        filters={ lazyParams.filters }
                        dataKey="id"
                        loading={ getDataTable?.isLoading }
                        onFilter={ dataHandler }
                        sortField={ lazyParams.sortField }
                        className="table"
                        sortOrder={ lazyParams.sortOrder }
                        totalRecords={ totalRecords }
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                    >
                        <Column field="fullName" header="Full Name" className='text-center' sortable filter Placeholder="Search by Brand Name" />
                        <Column field="email" header="Email" className='text-center' sortable filter  Placeholder="Search by Company Name" />
                        <Column field="mobile" header="Mobile" className='text-center' sortable />
                        <Column field="position" header="Position" className='text-center' sortable filter Placeholder="Search by Position" />
                        {/* <Column field="degree" header="Degree" className='text-center' sortable filter Placeholder="Search by Degree" /> */}
                        {/* <Column field="experience" header="Experience" className='text-center' sortable filter Placeholder="Search by Experience" /> */}
                        <Column field="career_type" header="Career Type" className='text-center' sortable filter Placeholder="Search by Career Type" />
                        {/* <Column field="package" header="Package" className='text-center' sortable  /> */}
                        <Column field="created_at" header="Created Date" className='text-center' sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>


            </div>
        </Layout>
    );
}

export default CareersIndex