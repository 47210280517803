import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';
import { getFormErrorMessage } from '../../../../config/global';
import { chooseOptions } from '../../../../config/constants';
import countries from '../../JsonFiles/countries.json';

export default function Step5 ({ errors, control, setValue }) {
  const { fileFormHandler, activeStep } = useClientGlobalContext();
  const addressUploadRef = useRef(null)
  const { t } = useTranslation("vendor");

  // Cancel selected file 
  const onCancelUpload = () => {
    fileFormHandler("address_attachment", null)
    setValue('address_attachment', null, { shouldValidate: true });
    addressUploadRef.current.clear();
  };

  const handleFileUpload = (e, onChange) => {
    const file = e.files[0];
    fileFormHandler("address_attachment", file)
    onChange(file);
    setValue('address_attachment', file, { shouldValidate: true });
  };

  return (
    <>
      <h1 className='col-12 h4 mb-3'>{ t('step5.title') }</h1>

      {/* Country Name*/ }
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize"> { t('step5.country_name') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="national_address[country]" control={ control }
            rules={ { required: activeStep >= 4 ?  'Country Name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Dropdown id={ field.name } { ...field } inputRef={ field.ref }
                filter showClear filterBy="label"
                onChange={ (e) => field.onChange(e.value) }
                options={ countries }
                optionLabel="label"
                optionValue="value"
                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                placeholder={ t("step5.choose_country") }
              />
            ) } />
        </span>
        { getFormErrorMessage('country_name', errors, "national_address") }
      </div>

      {/* City  */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize"> { t('step5.city') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="national_address[city]" control={ control }
            rules={ { required: activeStep >= 4 ?  'City is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                autoFocus
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('city', errors, "national_address") }
      </div>

      {/* Short Address */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.short_address') }</label>
        <span className="p-float-label mb-1 me-md-3" >
          <Controller name="national_address[short_address]" control={ control }
            rules={ { required: activeStep >= 4 ?  'Short address is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('short_address', errors, "national_address") }
      </div>

      {/* Building Number */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.building_number') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="national_address[building_number]" control={ control }
            rules={ { required: activeStep >= 4 ?  'Building number is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('building_number', errors, "national_address") }
      </div>

      {/* Street */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.street') }</label>
        <span className="p-float-label mb-1  me-md-3">
          <Controller name="national_address[street]" control={ control }
            rules={ { required: activeStep >= 4 ?  ' Street is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                autoFocus
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('street', errors, "national_address") }
      </div>

      {/* Secondary Number */ }
      <div className="col-12 col-md-6 field mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.secondary_number') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="national_address[secondary_number]" control={ control }
            rules={ { required: activeStep >= 4 ?  'Secondary number is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('secondary_number', errors, "national_address") }
      </div>


      {/* District */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.district') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="national_address[district]" control={ control }
            rules={ { required: activeStep >= 4 ?  'District is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('district', errors, "national_address") }
      </div>

      {/* Postal code */ }
      <div className="field col-12 col-md-6  mb-3">
        <label className="mb-1 text-capitalize">{ t('step5.postal_code') }</label>
        <span className="p-float-label mb-1  me-md-3">
          <Controller name="national_address[postal_code]" control={ control }
            rules={ { required: activeStep >= 4 ?  ' Postal code required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('postal_code', errors, "national_address") }
      </div>

      {/* Attachment */ }
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize"> { t('step5.attachment') }</label>
        <span className="p-float-label mb-1  me-md-3">
          <Controller name="address_attachment" control={ control }
            rules={ { required: activeStep >= 4 ?  'Attachment is required.' : false } }
            render={ ({ onChange, onBlur, field, fieldState }) => (
              <FileUpload
                id={ field.name } inputRef={ field.ref }
                { ...field }
                mode="basic"
                name="address_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                ref={ addressUploadRef }
                onSelect={ (e) => handleFileUpload(e, onChange) }
                onClick={ onCancelUpload }
                chooseOptions={ { ...chooseOptions, label: t('step5.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('address_attachment', errors) }
      </div>

    </>
  )
}

