import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { getFormErrorMessage } from '../../../../config/global'
import { chooseOptions } from '../../../../config/constants'
import { useCareerMutation } from '../../Queries';

import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
 
const _packages = [
    {
        label: "5,000 - 10,000",
        value: "5,000 - 10,000"
    },
    {
        label: "11,000 - 20,000",
        value: "11,000 - 20,000"
    },
    {
        label: "21,000 - 30,000",
        value: "21,000 - 30,000"
    },
    {
        label: "31,000+",
        value: "31,000+"
    },
]

export default function CareerForm ({ activeOption }) {
    const { formState: { errors }, handleSubmit, control, setValue } = useForm();
    const careerMutation = useCareerMutation();
    const { t } = useTranslation("careers");

    const resumeUploadRef = useRef(null);
    const [files, seFiles] = useState({
        resume: {},
        uLetter: {},
    })

    // Cancel selected file 
    const onCancelUpload = () => {
        seFiles(prev => ({ ...prev, resume: {} }))
        setValue('resume', null, { shouldValidate: true });
        resumeUploadRef.current.clear();
    };

    const handleFileUpload = (e, onChange) => {
        const file = e.files[0];
        seFiles(prev => ({ ...prev, resume: file }))
        onChange(file);
        setValue('resume', file, { shouldValidate: true });
    };

    const _inputs = {
        "INTERNSHIPS": <InternshipInputs errors={ errors } control={ control } t={ t } setValue={ setValue } seFiles={ seFiles } />,
        "FULL-TIME": <FullTimeInputs errors={ errors } control={ control } t={ t } />,
        "FREELANCING": <FreelanceInputs errors={ errors } control={ control } t={ t } />,
    }

    const submitForm = async  (data) => {
         var formData = new FormData();

        formData.append("career_type",  activeOption?.key );
        formData.append("fullName", data?.name);
        formData.append("mobile", data?.mobile);
        formData.append("email", data?.email);
        formData.append("resume_upload", files?.resume);

        if (activeOption?.key === "FULL-TIME") {
            formData.append("position", data?.position);
            formData.append("package", data?.package);
        } else if (activeOption?.key === "INTERNSHIPS") {
            formData.append("degree", data?.degree);
            formData.append("university_letter", files?.uLetter);
        } else if (activeOption?.key === "FREELANCING") {
            formData.append("experience", data?.experience);
            formData.append("package", data?.package);
        }

        
        await careerMutation.mutateAsync(formData)
    }

    return (
        <div className='career-form-container effect-3d-shadow rounded-[40px]'>
            <form action="" className='py-5 px-3 px-md-5 d-flex flex-column' onSubmit={ handleSubmit(submitForm) }>
                <div className="col-12 d-flex flex-lg-row flex-column mb-3">
                    {/* name */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-lg-0 mb-3">
                        <label className="mb-1 text-capitalize"> { t('form.name') }</label>
                        <span className="p-float-label mb-1">
                            <Controller name="name" control={ control }
                                rules={ { required: 'Name is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        autoFocus
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name', errors) }
                    </div>
                    {/* email */ }
                    <div className="col-12 col-lg-6 field me-4">
                        <label className="mb-1 text-capitalize">{ t('form.email') }</label>
                        <span className="p-float-label mb-1">
                            <Controller name="email" control={ control }
                                rules={ {
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message: "Invalid Email Address!",
                                    },
                                } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('email', errors) }
                    </div>
                </div>
                {/* mobile */ }
                <div className="field mb-3 col-12">
                    <label className="mb-1 text-capitalize">{ t('form.mobile') }</label>
                    <span className="p-float-label mb-1">
                        <Controller name="mobile" control={ control }
                            rules={ { required: 'Mobile is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputText
                                    id={ field.name }
                                    { ...field }
                                    className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                            ) } />
                    </span>
                    { getFormErrorMessage('mobile', errors) }
                </div>

                {/* Get required inputs based on selected option */ }
                { _inputs?.[activeOption.key] }

                <div className="field mb-3 col-12">
                    <label className="mb-1 text-capitalize"> { t('form.resume') }</label>
                    <span className="p-float-label mb-1">
                        <Controller name="resume" control={ control }
                            rules={ { required: 'Resume is required.' } }
                            render={ ({ onChange, onBlur, field, fieldState }) => (
                                <FileUpload
                                    id={ field.name }
                                    { ...field }
                                    mode="basic"
                                    name="resume"
                                    accept=".pdf"
                                    maxFileSize={ 1000000000 }
                                    customUpload
                                    ref={ resumeUploadRef }
                                    onSelect={ (e) => handleFileUpload(e, onChange) }
                                    onClick={ onCancelUpload }
                                    chooseOptions={ { ...chooseOptions, label: t('form.upload') } }
                                    className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    icon="none"
                                />
                            ) } />
                    </span>
                    { getFormErrorMessage('resume', errors) }
                </div>

                <div className="field mt-3 col-12">
                    <button className='text-center fw-bold fs-4 w-100 p-3 bg-black rounded-[20px] btn-shadow'>Apply</button>
                </div>
            </form>
        </div>
    )
}

export const FullTimeInputs = ({ control, errors, t }) => {
    return (
        <>
            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.position') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="position" control={ control }
                        rules={ { required: 'Position is required.' } }
                        render={ ({ field, fieldState }) => (
                            <InputText
                                id={ field.name }
                                { ...field }
                                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                        ) } />
                </span>
                { getFormErrorMessage('position', errors) }
            </div>

            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.choose_package') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="package" control={ control }
                        rules={ { required: 'package is required.' } }
                        render={ ({ field, fieldState }) => (
                            <Dropdown id={ field.name } { ...field }
                                onChange={ (e) => field.onChange(e.value) }
                                options={ _packages }
                                optionLabel="label"
                                optionValue="value"
                                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                            />
                        ) } />
                </span>
                { getFormErrorMessage('package', errors) }
            </div>
        </>
    )
}

export const InternshipInputs = ({ control, errors, t, setValue, seFiles }) => {
    const uLetterRef = useRef(null);

    const onCancelUpload = () => {
        seFiles(prev => ({ ...prev, uLetter: {} }))
        setValue('university_letter', null, { shouldValidate: true });
        uLetterRef.current.clear();
    };

    const handleULetterUpload = (e, onChange) => {
        const file = e.files[0];
        seFiles(prev => ({ ...prev, uLetter: file }))
        onChange(file);
        setValue('university_letter', file, { shouldValidate: true });
    };


    return (
        <>
            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.degree') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="degree" control={ control }
                        rules={ { required: 'Degree is required.' } }
                        render={ ({ field, fieldState }) => (
                            <InputText
                                id={ field.name }
                                { ...field }
                                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                        ) } />
                </span>
                { getFormErrorMessage('degree', errors) }
            </div>

            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.university_letter') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="university_letter" control={ control }
                        rules={ { required: 'University letter is required.' } }
                        render={ ({ onChange, field, fieldState }) => (
                            <FileUpload
                                id={ field.name }
                                { ...field }
                                mode="basic"
                                name="university_letter"
                                accept=".pdf"
                                maxFileSize={ 1000000000 }
                                customUpload
                                ref={ uLetterRef }
                                onSelect={ (e) => handleULetterUpload(e, onChange) }
                                onClick={ onCancelUpload }
                                chooseOptions={ { ...chooseOptions, label: t('form.upload') } }
                                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                icon="none"
                            />
                        ) } />
                </span>
                { getFormErrorMessage('university_letter', errors) }
            </div>
        </>
    )
}

export const FreelanceInputs = ({ control, errors, t }) => {
    return (
        <>
            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.experience') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="experience" control={ control }
                        rules={ { required: 'Experience is required.' } }
                        render={ ({ field, fieldState }) => (
                            <InputText
                                id={ field.name }
                                { ...field }
                                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                        ) } />
                </span>
                { getFormErrorMessage('experience', errors) }
            </div>

            <div className="field mb-3 col-12">
                <label className="mb-1 text-capitalize"> { t('form.choose_package_per_project') }</label>
                <span className="p-float-label mb-1">
                    <Controller name="package_per_project" control={ control }
                        rules={ { required: 'Package is required.' } }
                        render={ ({ field, fieldState }) => (
                            <Dropdown id={ field.name } { ...field }
                                onChange={ (e) => field.onChange(e.value) }
                                options={ _packages }
                                optionLabel="label"
                                optionValue="value"
                                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                            />
                        ) } />
                </span>
                { getFormErrorMessage('package_per_project', errors) }
            </div>
        </>
    )
}