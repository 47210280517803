import React from "react";
 
import "./css/Menu.css";
import MenuList from "./MenuList";

const Menu = () => {

  return (
       <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
        <MenuList />
      </aside>
   );
};

export default Menu;
