import React from "react";

import { useTitle } from "../../../config/global";
import Layout from "../../Admin/Layout/Layout";
import CareersDataTable from "./components/CareersDataTable";
import VendorsDataTable from "./components/VendorsDataTable";

const Dashboard = () => {
  useTitle("Dashboard Page");
  const statistics = {}
  
  return (
    <Layout>
      <div className="content-wrapper my-4">
        <div className="px-0 flex-grow-1 ">
          <div className="row">
            {/* <div className="col-lg-12 col-md-4 order-0">
              <div className="row ">
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body d-flex align-items-center p-3">
                      <div className="d-flex flex-column">
                        <span className="text-primary mb-1">Total Ads</span>
                        <h3 className="mb-0 text-pink fw-bolder">{ statistics?.totalScheduleAds }</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body d-flex align-items-center p-3">
                      <div className="d-flex flex-column">
                        <span className="text-primary mb-1">Pending Ads</span>
                        <h3 className="mb-0 text-pink fw-bolder">{ statistics?.totalPendingAds }</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body d-flex align-items-center p-3">
                      <div className="d-flex flex-column">
                        <span className="text-primary mb-1">Published Ads</span>
                        <h3 className="mb-0 text-pink fw-bolder">{ statistics?.totalPublishedAds }</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body d-flex align-items-center p-3">
                      <div className="d-flex flex-column">
                        <span className="text-primary mb-1">Archived Ads</span>
                        <h3 className="mb-0 text-pink fw-bolder">{ statistics?.totalArchiveAds }</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 ">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title m-0 me-2 fs-4">Vendors Requests</h6>
                </div>
                <VendorsDataTable />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title m-0 me-2 fs-4">Careers Requests</h6>
                </div>
                <CareersDataTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;
