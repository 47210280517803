import React, { useEffect } from "react";

const Footer = () => {

    return (
        <>
            <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-center py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                        Copyright © { new Date().getFullYear() } Ubitc Inc
                    </div>
                </div>
            </footer>
            <div className="content-backdrop fade" />
        </>
    );

}
export default Footer;
