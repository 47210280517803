import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { chooseOptions } from '../../../../config/constants';
import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';
import { getFormErrorMessage } from '../../../../config/global';
import countriesCodes from '../../JsonFiles/countriesCods.json';
import currencyCodes from '../../JsonFiles/currencyCodes.json';

export default function Step4 ({ errors, control, setValue }) {
  const { fileFormHandler, activeStep } = useClientGlobalContext();
  const backCertificationRef = useRef(null)
  const { t } = useTranslation("vendor");

  // Cancel selected file 
  const onCancelUpload = () => {
    fileFormHandler("bank_certification_attachment", null)
    setValue('bank_certification_attachment', null, { shouldValidate: true });
    backCertificationRef.current.clear();
  };

  const handleFileUpload = (e, onChange) => {
    const file = e.files[0];
    fileFormHandler("bank_certification_attachment", file)
    onChange(file);
    setValue('bank_certification_attachment', file, { shouldValidate: true });
  };

  return (
    <>
      <h1 className='col-12 h4 mb-3'>{ t('step4.title') }</h1>

      {/* Bank Name */ }
      <div className={ `field mb-3 col-12 col-md-6` } >
        <label className="mb-1 text-capitalize"> { t('step4.bank_name') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[bank_name]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Bank name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                autoFocus
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('bank_name', errors, "bank_information") }
      </div>

      {/* Branch Name  */ }
      <div className={ `field mb-3 col-12 col-md-3` } >
        <label className="mb-1 text-capitalize">{ t('step4.branch_name') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[branch_name]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Branch Name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name }
                { ...field }
                autoFocus
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('branch_name', errors, "bank_information") }
      </div>

      {/* Bank Code */ }
      <div className={ `field mb-3 col-12 col-md-3` } >
        <label className="mb-1 text-capitalize">{ t('step4.bank_code') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[bank_code]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Bank code input is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('bank_code', errors, "bank_information") }
      </div>

      {/* Account Name */ }
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize">{ t('step4.account_name') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[account_name]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Account name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('account_name', errors, "bank_information") }
      </div>


      {/* swift code*/ }
      <div className="field col-12 col-md-3 ">
        <label className="mb-1 text-capitalize"> { t('step4.swift_code') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[swift_code]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Account currency code is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name } inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('swift_code', errors, "bank_information") }
      </div>

      {/* Account number*/ }
      <div className="field col-12 col-md-3 ">
        <label className="mb-1 text-capitalize"> { t('step4.account_number') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[account_number]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Account currency code is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('account_number', errors, "bank_information") }
      </div>

      {/* IBAN */ }
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize">{ t('step4.iban') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[iban]" control={ control }
            rules={ { required: activeStep >= 3 ? 'IBAN is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('iban', errors, "bank_information") }
      </div>

      {/*Invoice currency */ }
      <div className="field col-12 col-md-3">
        <label className="mb-1 text-capitalize"> { t('step4.invoice_currency') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[invoice_currency]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Invoice currency is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Dropdown id={ field.name } { ...field } inputRef={ field.ref }
                onChange={ (e) => field.onChange(e.value) }
                filter showClear filterBy="label"
                options={ currencyCodes }
                optionLabel="label"
                optionValue="value"
                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                placeholder={ t("step4.currency") }
              />
            ) } />
        </span>
        { getFormErrorMessage('invoice_currency', errors, "bank_information") }
      </div>

      {/* Account currency code*/ }
      <div className="field col-12 col-md-3 ">
        <label className="mb-1 text-capitalize"> { t('step4.account_currency_code') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[account_currency_code]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Account currency code is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Dropdown id={ field.name } { ...field } inputRef={ field.ref }
                onChange={ (e) => field.onChange(e.value) }
                filter showClear filterBy="label"
                options={ currencyCodes }
                optionLabel="label"
                optionValue="value"
                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                placeholder={ t("step4.currency") }
              />
            ) } />
        </span>
        { getFormErrorMessage('account_currency_code', errors, "bank_information") }
      </div>

      {/* Bank certification */ }
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize"> { t('step4.bank_certification') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_certification_attachment" control={ control }
            rules={ { required: activeStep >= 3 ? 'Bank certification is required.' : false } }
            render={ ({ onChange, onBlur, field, fieldState }) => (
              <FileUpload
                id={ field.name } inputRef={ field.ref }
                { ...field }
                mode="basic"
                name="bank_certification_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                ref={ backCertificationRef }
                onSelect={ (e) => handleFileUpload(e, onChange) }
                onClick={ onCancelUpload }
                chooseOptions={ { ...chooseOptions, label: t('step4.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('bank_certification_attachment', errors) }
      </div>


      {/* Account country code*/ }
      <div className="field col-12 col-md-3 ">
        <label className="mb-1 text-capitalize"> { t('step4.account_country_code') }</label>
        <span className="p-float-label mb-3 me-md-3">
          <Controller name="bank_information[account_country_code]" control={ control }
            rules={ { required: activeStep >= 3 ? 'Account currency code is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Dropdown id={ field.name } { ...field } inputRef={ field.ref }
                onChange={ (e) => field.onChange(e.value) }
                filter showClear filterBy="label"
                options={ countriesCodes }
                optionLabel="label"
                optionValue="value"
                className={ `w-100 input-3d-shadow ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                placeholder={ t("step4.currency") }
              />
            ) } />
        </span>
        { getFormErrorMessage('account_country_code', errors, "bank_information") }
      </div>


    </>
  )
}

