import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import { Checkbox } from "primereact/checkbox"

import Layout from '../Layout/Layout'
import CareerForm from './components/CareerForm';
import { useTitle } from '../../../config/global';

function Careers () {
  useTitle('Careers');
  const { t } = useTranslation("careers");

  const checkOptions = [
    {
      index: 1,
      key:"FULL-TIME",
      label: "checkbox_1.label",
      desc: "checkbox_1.desc",
    },
    {
      index: 2,
      key:"INTERNSHIPS",
      label: "checkbox_2.label",
      desc: "checkbox_2.desc",
    },
    {
      index: 3,
      key:"FREELANCING",
      label: "checkbox_3.label",
      desc: "checkbox_3.desc",
    },
  ]

  const [activeOption, setActiveOption] = useState(checkOptions[0]);

  return (
    <Layout>
      <div  className="d-flex flex-lg-row flex-column justify-content-xxl-between pt-32 pb-5 px-md-4 mx-3 mx-lg-5 careers_container"  >
        <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-center pe-5">
          <h1 className='h1 mb-5 text-uppercase fw-bolder '>{ t("title") } <span className='text-yellow '>{ t("title-color") }</span></h1>
          <p className="fs-5 mb-2">{ t("p_1") }</p>
          <div className="d-flex flex-xl-row flex-column mb-3 mb-md-5">
            {
              checkOptions.map((option, index) => {
                return (
                  <div className=" col-12 col-xl-4 p-2 options_container position-relative">
                    <div className={ `field-checkbox d-flex align-items-center p-3 rounded-[10px] ${ activeOption?.index === option?.index ? "active_scope" : "inactive_scope" }` } >
                      <Checkbox inputId={ `option-${ index }` } name="city" value="San Francisco" className='me-2 '
                        onChange={ () => setActiveOption(option) } checked={ activeOption?.index === option?.index } />
                      <label htmlFor={ `option-${ index }` } className="cursor-pointer fs-5 text-uppercase ">{ t(`${ option?.label }`) }</label>
                    </div>
                  </div>
                )
              })
            }

          </div>
          <p className="fs-5 mb-3">
            {
              t(`${ activeOption?.desc }`)
            }
          </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-12 px-lg-4">
          <CareerForm activeOption={ activeOption} />
        </div>
      </div>
    </Layout>
  )
}

export default Careers