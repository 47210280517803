import React, { useContext, createContext, useEffect, useState, useRef } from "react";
import { useGetDataTable } from "../Queries";

const joinedTables = ["event_schedule", "event_schedule_user"]

const DataTableContext = createContext({});

export const DataTableProvider = (props) => {
    const getDataTable = useGetDataTable();

    let loadLazyTimeout = null;
    const didMountRef = useRef(null)

    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState();
    const [id, setId] = useState("");

    const [lazyParams, setLazyParams] = useState({
        modelName: "",
        first: 0,
        rows: 15,
        page: 0,
        sortField: 'id',
        sortOrder: 1,
        filters: {},
    });

    const onPage = (event) => {
        event['modelName'] = lazyParams.modelName;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event['modelName'] = lazyParams.modelName;
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['modelName'] = lazyParams.modelName;
        event['first'] = 0;
        event['page'] = 0;

        setLazyParams(event);
    }

    const dataHandler = (event) => {
        event['modelName'] = lazyParams.modelName;
        event['first'] = 0;
        event['page'] = 0;

        setLazyParams(event);
    }

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(async () => {
            await getDataTable.mutateAsync(lazyParams, {
                onSuccess: async (data) => {
                    setTotalRecords(data.pagination.total);
                    setLoading(false);
                    setData(data.data);
                }
            })
        }, Math.random() * 1000 + 250);
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            loadLazyData();
        }
    }, [lazyParams])

    useEffect(() => {
        if (reload) {
            loadLazyData()
        }
        setReload(false)
    }, [reload])

    useEffect(() => {
        return () => {
            setData([])
            setReload(false)
            setTotalRecords(0)
        }
    }, [])

    return (
        <DataTableContext.Provider value={ {
            totalRecords, setTotalRecords,
            lazyParams, setLazyParams,
            data, setData,
            loading, setLoading,
            setReload,
            getDataTable,
            dataHandler,
            onPage,
            onSort,
            onFilter,
            setId
        } }>
            { props.children }
        </DataTableContext.Provider>
    )
}

export const useDataTableContext = () => {
    return useContext(DataTableContext);
}