import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import { getFormErrorMessage } from "../../../../config/global";
import {
  chooseOptions,
  scopeOfWorkOptions,
} from "../../../../config/constants";

export default function Step1({ errors, control, setValue }) {
  const { fileFormHandler, activeStep, setVendorType, vendorType } =
    useClientGlobalContext();
  const companyProfileUploadRef = useRef(null);
  const { t } = useTranslation("vendor");

  // Cancel selected file
  const onCancelUpload = () => {
    fileFormHandler("company_profile_attachment", null);
    setValue("company_profile_attachment", null, { shouldValidate: true });
    companyProfileUploadRef.current.clear();
  };

  const handleFileUpload = (e, onChange) => {
    const file = e.files[0];
    fileFormHandler("company_profile_attachment", file);
    onChange(file);
    setValue("company_profile_attachment", file, { shouldValidate: true });
  };

  return (
    <>
      <h1 className="col-12 h4 mb-3 ">{t("step1.title")}</h1>

      {/* Brand Name */}
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize"> {t("step1.brand_name")}</label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller
            name="brand_name"
            control={control}
            rules={{
              required: activeStep === 0 ? "Brand name is required." : false,
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                inputRef={field.ref}
                {...field}
                autoFocus
                className={`input-3d-shadow rounded-[20px] w-100 ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </span>
        {getFormErrorMessage("brand_name", errors)}
      </div>

      {/* Company Name */}
      <div className="field mb-3  col-12 col-md-6">
        <label className="mb-1 text-capitalize">
          {t("step1.company_name")}
        </label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller
            name="company_name"
            control={control}
            rules={{
              required: activeStep === 0 ? "Company name is required." : false,
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                inputRef={field.ref}
                {...field}
                className={`input-3d-shadow rounded-[20px] w-100 ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </span>
        {getFormErrorMessage("company_name", errors)}
      </div>

      {/* Year of experience */}
      <div className="field mb-3  col-12 col-md-6">
        <label className="mb-1 text-capitalize">
          {t("step1.experience_years")}
        </label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller
            name="years_of_experience"
            control={control}
            rules={{
              required:
                activeStep === 0
                  ? "Years of experience input is required."
                  : false,
            }}
            render={({ field, fieldState }) => (
              <input
                inputRef={field.ref}
                type="number"
                id={field.name}
                {...field}
                className={`p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
        </span>
        {getFormErrorMessage("years_of_experience", errors)}
      </div>

      {/* Company profile file */}
      <div className="field mb-3 col-12 col-md-6">
        <label className="mb-1 text-capitalize">
          {" "}
          {t("step1.company_profile")}
        </label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller
            name="company_profile_attachment"
            control={control}
            rules={{
              required:
                activeStep === 0 ? "Company profile is required." : false,
            }}
            render={({ onChange, onBlur, field, fieldState }) => (
              <FileUpload
                id={field.name}
                {...field}
                mode="basic"
                name="company_profile_attachment"
                accept=".pdf"
                maxFileSize={1000000000}
                customUpload
                ref={companyProfileUploadRef}
                onSelect={(e) => handleFileUpload(e, onChange)}
                onClick={onCancelUpload}
                chooseOptions={{ ...chooseOptions, label: t("step1.upload") }}
                className={`w-100 ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
                icon="none"
                inputRef={field.ref}
              />
            )}
          />
        </span>
        {getFormErrorMessage("company_profile_attachment", errors)}
      </div>

      {/*Scope of Work */}
      <div className="field mb-3  col-12 col-md-6">
        <label className="mb-1 text-capitalize">
          {" "}
          {t("step1.scope_of_work")}
        </label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller
            name="scope_of_work"
            control={control}
            rules={{
              required: activeStep === 0 ? "Scope of work is required." : false,
            }}
            render={({ field, fieldState }) => (
              <Dropdown
                inputRef={field.ref}
                id={field.name}
                {...field}
                onChange={(e) => field.onChange(e.value)}
                options={scopeOfWorkOptions}
                optionLabel="label"
                optionValue="value"
                className={`w-100 input-3d-shadow ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
                placeholder={t("step1.choose_scope")}
              />
            )}
          />
        </span>
        {getFormErrorMessage("scope_of_work", errors)}
      </div>

      {/*Vendor type */}
      <div className="field  col-12 col-md-6 my-auto">
        <span className="p-float-label mb-1 pe-md-3 ">
          <Controller
            name="vendor_type"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex items-center ">
                <div className="col-6 flex align-items-center">
                  <RadioButton
                    id={field.name}
                    {...field}
                    inputId="local"
                    name="vendor_type"
                    value="local"
                    inputRef={field.ref}
                    onChange={(e) => setVendorType(e.value)}
                    checked={vendorType === "local"}
                  />
                  <label htmlFor="local" className="ms-5">
                    {t("step1.local_vendor")}
                  </label>
                </div>
                <div className="col-6 flex align-items-center">
                  <RadioButton
                    id={field.name}
                    {...field}
                    inputId="foreign"
                    name="vendor_type"
                    value="foreign"
                    inputRef={field.ref}
                    onChange={(e) => {
                      field.onChange(e.value);
                      setVendorType(e.value);
                    }}
                    checked={vendorType === "foreign"}
                  />
                  <label htmlFor="foreign" className="ms-5">
                    {t("step1.foreign_vendor")}
                  </label>
                </div>
              </div>
            )}
          />
        </span>
      </div>
    </>
  );
}
