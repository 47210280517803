import _ from "lodash";
import { useEffect } from "react";

function getProfile () {
  return {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    image: localStorage.getItem("image"),
    phone: localStorage.getItem("phone"),
    token: localStorage.getItem("token"),
  };
}

function setProfile (data) {
  localStorage.setItem("name", data?.name);
  localStorage.setItem("email", data?.email);
  localStorage.setItem("phone", data?.phone);
  localStorage.setItem("token", data?.token);
}

function dateFormate (val) {
  let objectDate = new Date(val);

  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();

  return `${ year }-${ month }-${ day }`;
}

function useTitle (title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = "Luxury KSA - " + title;
    return () => {
      document.title = "Luxury KSA - " + prevTitle;
    };
  });
}

//To format media size
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function formatSize (x) {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

//To Format Total duration HH:MM:SS
function formatDuration (_totalDuration) {
  let totalDuration = Math.ceil(_totalDuration);
  let hours = Math.floor(totalDuration / 3600);
  let minutes = Math.floor((totalDuration - hours * 3600) / 60);
  let seconds = totalDuration - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

//get forms validation errors
const getFormErrorMessage = (name, errors, obj = null) => {
  if (!_.isNull(obj))
    return (
      errors?.[obj]?.[name] && (
        <small className="text-danger fw-bold p-error">
          { errors?.[obj]?.[name]?.message }
        </small>
      )
    );
  else
    return (
      errors?.[name] && (
        <small className="text-danger fw-bold p-error">
          { errors?.[name]?.message }
        </small>
      )
    );
};

//Get timezone
const date = new Date();
const englishTimeZoneFormatter = new Intl.DateTimeFormat("en", {
  timeZoneName: "long",
});
const englishTimeZone = englishTimeZoneFormatter
  .formatToParts(date)
  .find((part) => part.type === "timeZoneName").value;

// Used in drag and drop components
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: { ...sourceColumn, items: sourceItems },
      [destination.droppableId]: { ...destColumn, items: destItems },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: { ...column, items: copiedItems },
    });
  }
};

const getDirection = (language) => {
  const rtlLanguages = ["ar", "he", "fa"]; // Add other RTL languages if needed
  return rtlLanguages.includes(language) ? "rtl" : "ltr";
};

export const loadLanguageSpecificCss = async (lang) => {
  // const cssFiles = {
  //   en: { custom: () => import('../assets/css/en/index.css') },
  //   ar: { custom: () => import('../assets/css/ar/index.css') }
  // };
  // document.querySelectorAll(`[id^="custom-css-"]`).forEach(link => link.remove());
  // const loadCss = (id, href) => {
  //   let link = document.getElementById(id);
  //   if (link) {
  //     link.href = href;
  //   } else {
  //     link = document.createElement('link');
  //     link.id = id;
  //     link.rel = 'text/css';
  //     link.href = href;
  //     document.getElementById('root').appendChild(link);
  //   }
  // };
  // const langFiles = cssFiles[lang] || cssFiles['en'];
  // const customModule = await langFiles.custom();
  // loadCss(`custom-css-${ lang }`, customModule.default);
};

export const loadCSS = (url) => {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
  });
};

export {
  englishTimeZone,
  onDragEnd,
  getProfile,
  setProfile,
  useTitle,
  dateFormate,
  formatSize,
  getDirection,
  formatDuration,
  getFormErrorMessage,
};
