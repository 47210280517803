import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Step3() {
  const { t } = useTranslation("box_offices");
  const [sessionId, setSessionId] = useState(
    "0044174D48708702A28F5E236A1C603B.uat01-vm-tx03"
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sessionId) {
      const script = document.createElement("script");
      script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${sessionId}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [sessionId]);
  return (
    <div className="mx-auto max-w-screen-xl px-4 2xl:px-0 py-16">
      {/* <div></div> */}
      <div className="col-12 col-md-12 pe-2 my-auto  ">
        {sessionId ? (
          <form
            action="/box-offices"
            className="paymentWidgets ms-auto"
            data-brands="MADA VISA MASTER "
          ></form>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Step3;
