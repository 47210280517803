import { useMutation } from 'react-query';
import axiosInstance from '../../../config/Axios';

import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';

const joinedDataTable = ["event_schedule", "event_schedule_user"]

const getDataTable = async (payload) => {
    let ordered_col = payload.sortField ? payload.sortField : 'created_at';
    let url = ""

    const pushed = {
        "order_by": {
            [ordered_col]: payload.sortOrder == 1 ? "desc" : 'asc'
        },
        "filters": payload.filters,
        "filters_date": payload.filters_date,
    };

    if (joinedDataTable.indexOf(payload?.modelName) !== -1) {
        url = `/datatable/${ payload?.modelName }?page=${ payload.page + 1 }&per_page=${ payload.rows }`;
    } else
        url = `/datatable/${ payload?.modelName }/view?page=${ payload.page + 1 }&per_page=${ payload.rows }`;

    const { data } = await axiosInstance.post(url, pushed);

    return data
}

export const useGetDataTable = () => {
    const { showToast } = useAdminGlobalContext();

    return useMutation(getDataTable, {
        onError: (error) => showToast("error", "Error ", error.response?.data?.message)
    })
}

//-------------------------------------------------Delete DataTable Row-------------------------------------------------
const deleteRow = async (payload) => {
    const { data } = await axiosInstance.delete(`/datatable/${ payload?.table }/${ payload?.id }/delete`);

    return data.data
}

export const useDeleteDataTableRow = () => {
    const { showToast } = useAdminGlobalContext();

    return useMutation(deleteRow, {
        onError: (error) => showToast("error", "Error ", error.response?.data?.message)
    })
}
