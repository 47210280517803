import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Navigate, useParams } from 'react-router-dom';
import { useGetVendorById, useVendorStatusHandler } from '../../Queries'
import { useAdminGlobalContext } from '../../Contexts/AdminGlobalContext';
import PdfViewer from '../../SharedComponents/PdfViewer';
import { Button } from 'primereact/button';

function VendorDetails () {
  const { dialogHandler, openDialog } = useAdminGlobalContext();
  const getVendorDetails = useGetVendorById();
  const updateStatus = useVendorStatusHandler();

  const { id } = useParams();

  const [vendorInfo, setVendorInfo] = useState();
  const [selectedPDF, setSelectedPDF] = useState("");
  const [isLocal, setIsLocal] = useState(true);

  const getData = async () => {
    await getVendorDetails.mutateAsync(id, {
      onSuccess: (data) => {
        setVendorInfo(data)
         setIsLocal(data?.vendor_type === "LOCAL")
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const previewHandler = (url) => {
    setSelectedPDF(url);
    dialogHandler("pdfViewer")
  }

  // Data Table Body Template
  const updateHandler = async (status) => {
    await updateStatus.mutateAsync({
      id: vendorInfo?.id,
      data: {
        status: status
      }
    }, {
      onSuccess: () => { getData() }
    })
  }

  return (
    <Layout>
      <div className="d-flex justify-content-between">
        <div className="h3 mt-3">Vendors Details</div>
        {
          vendorInfo?.status === "PENDING" ?
            <div className="d-flex align-items-center">
              <Button label="Reject" icon="pi pi-times" className="py-2 me-3 p-button-raised bg-danger border-danger" onClick={ () => { updateHandler("REJECTED") } } />
              <Button label="Accept" icon="pi pi-check" className="py-2 p-button-raised  bg-success border-success" onClick={ () => { updateHandler("APPROVE") } } />
            </div>
            :
            <></>
        }

      </div>

      <Accordion activeIndex={ 0 } className="mt-3">
        <AccordionTab header="Personal Information" className=''>
          <div className="d-flex flex-nowrap">
            <div className="col-6 d-flex pe-4 flex-nowrap">
              <div className="col-6">
                <div className="bg-[#191924] me-2">
                  <div className="w-100 py-3 px-4 border-bottom">First Name</div>
                  <div className="w-100 py-3 px-4 border-bottom">Last Name</div>
                  <div className="w-100 py-3 px-4 border-bottom">Mobile Number</div>
                  <div className="w-100 py-3 px-4 border-bottom">Email Address</div>
                  <div className="w-100 py-3 px-4 border-bottom">Position</div>
                  { isLocal ?
                    <>
                      <div className="w-100 py-3 px-4 border-bottom">Country</div>
                      <div className="w-100 py-3 px-4 ">City</div>
                    </>
                    :
                    <></>
                  }

                </div>
              </div>
              <div className="col-6">
                <div className="bg-[#1E1E1E]">
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.point_of_contact?.first_name }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.point_of_contact?.last_name }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.point_of_contact?.mobile_number }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.point_of_contact?.Email_address }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.point_of_contact?.position }</div>
                  {
                    isLocal ?
                      <>
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.country }</div>
                        <div className="w-100 py-3 px-4 ">{ vendorInfo?.national_address?.city }</div>
                      </>
                      :
                      <></>
                  }

                </div>
              </div>
            </div>
            {
              isLocal ?
                <>
                  <div className="col-6 d-flex me-2 flex-nowrap">
                    <div className="col-6">
                      <div className="bg-[#191924] me-2">
                        <div className="w-100 py-3 px-4 border-bottom">Street</div>
                        <div className="w-100 py-3 px-4 border-bottom">Building Number</div>
                        <div className="w-100 py-3 px-4 border-bottom">Secondary Number</div>
                        <div className="w-100 py-3 px-4 border-bottom">District</div>
                        <div className="w-100 py-3 px-4 border-bottom">Postal Code</div>
                        <div className="w-100 py-3 px-4">National Address Document</div>
                      </div>
                    </div>
                    <div className="col-6 ">
                      <div className="bg-[#1E1E1E]">
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.street }</div>
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.building_number }</div>
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.secondary_number }</div>
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.district }</div>
                        <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.national_address?.postal_code }</div>
                        <div className="w-100 py-3 px-4 text-primary underline cursor-pointer" onClick={ () => { previewHandler(vendorInfo?.national_address?.attachment) } }>
                          Preview
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <></>
            }

          </div>
        </AccordionTab>

        <AccordionTab header="Commercial Information">
          <div className="d-flex flex-nowrap">
            <div className="col-12 d-flex flex-nowrap">
              <div className="col-4">
                <div className="bg-[#191924] me-2">
                  <div className="w-100 py-3 px-4 border-bottom border-secondary">DOCUMENT NAME</div>
                  <div className="w-100 py-3 px-4 border-bottom">Commercial Registration</div>
                  {
                    isLocal ?
                      <>
                        <div className="w-100 py-3 px-4 border-bottom">VAT</div>
                        <div className="w-100 py-3 px-4 border-bottom">GOSI</div>
                        <div className="w-100 py-3 px-4 border-bottom">Riyadh Chamber Membership</div>
                        <div className="w-100 py-3 px-4 border-bottom">Commercial Activity Licence</div>
                        <div className="w-100 py-3 px-4 ">Zakat, Tax and Customs Authority Certificate</div>                      </>
                      :
                      <></>
                  }
                </div>
              </div>

              <div className="col-8">
                <div className="bg-[#1E1E1E]">
                  <div className="bg-[#191924] w-100 py-3 px-4 border-bottom border-secondary d-flex ">
                    <div className="col-4 text-center">NUMBER </div>
                    <div className="col-4 text-center">VALIDITY</div>
                    <div className="col-4 text-center">DOCUMENT ATTACHMENT</div>
                  </div>

                  <div className=" w-100 py-3 px-4 border-bottom d-flex ">
                    <div className="col-4 text-center">{ vendorInfo?.commercial_registration?.number } </div>
                    <div className="col-4 text-center">{ vendorInfo?.commercial_registration?.validity } </div>
                    <div className="col-4 text-center text-primary underline cursor-pointer"
                      onClick={ () => { previewHandler(vendorInfo?.commercial_registration?.attachment) } }>
                      Preview
                    </div>
                  </div>
                  {
                    isLocal ?
                      <>
                        <div className=" w-100 py-3 px-4 border-bottom d-flex ">
                          <div className="col-4 text-center">{ vendorInfo?.vat?.number } </div>
                          <div className="col-4 text-center">{ vendorInfo?.vat?.validity } </div>
                          <div className="col-4 text-center text-primary underline cursor-pointer"
                            onClick={ () => { previewHandler(vendorInfo?.vat?.attachment) } }>
                            Preview
                          </div>
                        </div>

                        <div className=" w-100 py-3 px-4 border-bottom d-flex ">
                          <div className="col-4 text-center">{ vendorInfo?.gosi?.number } </div>
                          <div className="col-4 text-center">{ vendorInfo?.gosi?.validity } </div>
                          <div className="col-4 text-center text-primary underline cursor-pointer"
                            onClick={ () => { previewHandler(vendorInfo?.gosi?.attachment) } }>
                            Preview
                          </div>
                        </div>

                        <div className=" w-100 py-3 px-4 border-bottom d-flex ">
                          <div className="col-4 text-center">{ vendorInfo?.riyadh_chamber_membership?.number } </div>
                          <div className="col-4 text-center">{ vendorInfo?.riyadh_chamber_membership?.validity } </div>
                          <div className="col-4 text-center text-primary underline cursor-pointer"
                            onClick={ () => { previewHandler(vendorInfo?.riyadh_chamber_membership?.attachment) } }>
                            Preview
                          </div>
                        </div>

                        <div className=" w-100 py-3 px-4 border-bottom d-flex ">
                          <div className="col-4 text-center">{ vendorInfo?.commercial_activity_license?.number } </div>
                          <div className="col-4 text-center">{ vendorInfo?.commercial_activity_license?.validity } </div>
                          <div className="col-4 text-center text-primary underline cursor-pointer"
                            onClick={ () => { previewHandler(vendorInfo?.commercial_activity_license?.attachment) } }>
                            Preview
                          </div>
                        </div>

                        <div className=" w-100 py-3 px-4 d-flex ">
                          <div className="col-4 text-center">{ vendorInfo?.zakat_tax_customs_authority_certificate?.number } </div>
                          <div className="col-4 text-center">{ vendorInfo?.zakat_tax_customs_authority_certificate?.validity } </div>
                          <div className="col-4 text-center text-primary underline cursor-pointer"
                            onClick={ () => { previewHandler(vendorInfo?.zakat_tax_customs_authority_certificate?.attachment) } }>
                            Preview
                          </div>
                        </div>
                      </>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab header="Bank Information">
          <div className="d-flex flex-nowrap">
            <div className="col-6 d-flex pe-4 flex-nowrap">
              <div className="col-6">
                <div className="bg-[#191924] me-2">
                  <div className="w-100 py-3 px-4 border-bottom">Bank Name</div>
                  <div className="w-100 py-3 px-4 border-bottom">Bank Code</div>
                  <div className="w-100 py-3 px-4 border-bottom">Account Name</div>
                  <div className="w-100 py-3 px-4">Branch Name</div>
                </div>
              </div>
              <div className="col-6">
                <div className="bg-[#1E1E1E]">
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.bank_information?.bank_name }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.bank_information?.bank_code }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.bank_information?.account_name }</div>
                  <div className="w-100 py-3 px-4">{ vendorInfo?.bank_information?.branch_name }</div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex me-2 flex-nowrap">
              <div className="col-6">
                <div className="bg-[#191924] me-2">
                  <div className="w-100 py-3 px-4 border-bottom">Attach Bank Certificate:</div>
                  <div className="w-100 py-3 px-4 border-bottom">IBAN</div>
                  <div className="w-100 py-3 px-4 border-bottom">Account Currency Code</div>
                  <div className="w-100 py-3 px-4">Invoice Currency</div>
                </div>
              </div>
              <div className="col-6 ">
                <div className="bg-[#1E1E1E]">
                  <div className="w-100 py-3 px-4 border-bottom text-primary underline cursor-pointer"
                    onClick={ () => { previewHandler(vendorInfo?.bank_information?.attachment) } }
                  >
                    Preview
                  </div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.bank_information?.iban }</div>
                  <div className="w-100 py-3 px-4 border-bottom">{ vendorInfo?.bank_information?.account_currency_code }</div>
                  <div className="w-100 py-3 px-4">{ vendorInfo?.bank_information?.invoice_currency }</div>
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>

      </Accordion>

      { openDialog.pdfViewer ? <PdfViewer pdfURL={ selectedPDF } /> : <></> }
    </Layout>
  )
}

export default VendorDetails