
import axios from 'axios';

//Get timezone 
const date = new Date();
const englishTimeZoneFormatter = new Intl.DateTimeFormat('en', {
    timeZoneName: 'long'
});
const englishTimeZone = englishTimeZoneFormatter.formatToParts(date)
    .find(part => part.type === 'timeZoneName')
    .value;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const token = `${ localStorage.getItem("token") }`;
    if (token) {
        config.headers['Authorization'] = `${ token }`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['timezone'] = englishTimeZone;
    return config;
});

axiosInstance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export default axiosInstance;

axiosInstance.interceptors.response.use((response) => response,
    (error) => {

        if ((error.response.status == 401) && !(window.location.pathname === "/admin")) {
            localStorage.clear();
            window.location.href = '/admin';
        }

        return Promise.reject(error);
    })
