import React, { useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useTitle, getFormErrorMessage } from "../../config/global";

import "../../assets/vendor/css/pages/page-auth.css";

import landing from "../../assets/img/illustrations/boy-with-rocket-light.png";
import logo from "../../assets/img/logo.webp";

import { useLogInMutation } from "./Queries";

const Login = () => {
  useTitle("Login Page");

  const { register, formState: { errors }, handleSubmit, } = useForm();
  const token = localStorage.getItem("token");
  const logIn = useLogInMutation();



  const onSubmit = async (data) => {

    await logIn.mutateAsync(data)
  };


  return (token ?
    <Navigate to="/admin/dashboard" />
    :
    <>
      <div className="authentication-wrapper authentication-cover">
        <div className="authentication-inner row m-0">
          <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
            <div className="w-100 d-flex justify-content-center">
              <img
                src={ landing }
                className="img-fluid"
                width={ 700 }
                alt="landing"
              />
            </div>
          </div>
          <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              {/* Logo */ }
              <div className="app-brand mb-5">
                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                  <img src={ logo } className="img-fluid" width={ 200 } alt="logo" />
                </Link>
              </div>

              <form onSubmit={ handleSubmit(onSubmit) } className="my-4">
                <div className="mb-3 mt-4">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>

                  <input
                    { ...register("email", {
                      required: "Email Address is required",
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Invalid Email Address!",
                      },
                    }) }
                    className={ errors.email ? "form-control is-invalid" : "form-control" }
                    placeholder="Enter your email"
                  />
                  { getFormErrorMessage('email', errors) }
                </div>

                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    {/* <Link to="/forget/password">
                      <small>Forgot Password?</small>
                    </Link> */}
                  </div>
                  <div className="input-group">
                    <input
                      { ...register("password", {
                        required: "Password is required",
                      }) }
                      className={ errors.password ? "form-control is-invalid" : "form-control" }
                      aria-invalid={ errors.password ? "true" : "false" }
                      type="password"
                      placeholder="············"
                    />

                  </div>
                  { getFormErrorMessage('password', errors) }
                </div>
                <button className="btn btn-primary d-grid w-100 mt-5">
                  Sign in
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
