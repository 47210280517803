import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import logo from "../../../assets/img/logo.webp";
import "./css/Menu.css";

function MenuList() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    const pathname = window.location.pathname;
    const firstSlashIndex = pathname.indexOf("/");
    const secondSlashIndex = pathname.indexOf("/", firstSlashIndex + 1);
    const contentBetweenSlashes = pathname.substring(
      firstSlashIndex + 1,
      secondSlashIndex
    );

    setPathName(contentBetweenSlashes);
  }, []);

  const menuItem = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      icon: <i className="menu-icon bx bx-home-circle mb-1" />,
    },
    {
      path: "/admin/events",
      name: "Events",
      icon: <i className="menu-icon far fa-calendar-alt  mb-1"></i>,
    },
    {
      path: "/admin/careers",
      name: "Careers", 
      icon: <i className="menu-icon fas fa-briefcase  mb-1"></i>,
    },
    {
      path: "/admin/vendors",
      name: "Vendors",
      icon: <i className="menu-icon fas fa-store   mb-1"></i>,
    },
    {
      path: "/admin/setting",
      name: "Setting",
      icon: <i className="menu-icon fas fa-user-cog  mb-1"></i>,
    },
  ];

  const NavbarLink = ({ item, subItem = false }) => {
    return (
      <NavLink
        to={item.path}
        className={`menu-item ${
          pathName == item.name.toLocaleLowerCase() && "submenu" in item
            ? "active"
            : ""
        }`}
      >
        <li className="d-flex flex-row  p-2 menu-link">
          <div>{item.icon}</div>
          <div>{item.name}</div>
        </li>
      </NavLink>
    );
  };

  const SubMenu = ({ item }) => {
    return (
      <>
        <div
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={`menu-item ${
            pathName == item.name.toLocaleLowerCase() && "submenu" in item
              ? "active"
              : ""
          }`}
        >
          <li className="d-flex flex-column align-items-start p-2 menu-link cursor-pointer">
            <div className="d-flex flex-row  w-100">
              <div>{item.icon}</div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <span>{item.name}</span>
                {isMenuOpen ? (
                  <i className="fas fa-chevron-down fs-10"></i>
                ) : (
                  <i className="fas fa-angle-right fs-10"></i>
                )}
              </div>
            </div>
          </li>
        </div>
        {isMenuOpen && (
          <ul className="ps-3 d-block w-100 flex-grow-0 h-auto  menu-inner">
            {item.submenu.map((item, index) => {
              return <NavbarLink item={item} key={index} subItem={true} />;
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <>
      <div className="app-brand demo my-4">
        <NavLink to="/" className={`app-brand-link gap-2 mt-3 mb-3 m-auto`}>
          <img src={logo} className="img-fluid" width={160} alt="" />
        </NavLink>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner">
        {menuItem.map((Item, index) => {
          return "submenu" in Item ? (
            <SubMenu item={Item} key={index} />
          ) : (
            <NavbarLink item={Item} key={index} />
          );
        })}
      </ul>
    </>
  );
}

export default MenuList;
