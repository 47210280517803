import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils';

import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';
import { getFormErrorMessage } from '../../../../config/global';
 
export default function Step6 ({ errors, control }) {
  const { activeStep} = useClientGlobalContext();
   const { t } = useTranslation("vendor");
 

  return (
    <>
      <h1 className='col-12 h4 mb-3'>{ t('step6.title') }</h1>

      {/* First name  */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize"> { t('step6.first_name') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="point_of_contact[first_name]" control={ control }
            rules={ { required: activeStep === 5 ? 'First name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name }
                { ...field }
                autoFocus
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('first_name', errors, "point_of_contact") }
      </div>

      {/* Last Name */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step6.last_name') }</label>
        <span className="p-float-label mb-1">
          <Controller name="point_of_contact[last_name]" control={ control }
            rules={ { required: activeStep === 5 ? 'Last name is required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('last_name', errors, "point_of_contact") }
      </div>

      {/* mobile number */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step6.mobile') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="point_of_contact[mobile_number]" control={ control }
            rules={ { required: activeStep === 5 ? 'Mobile Number is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('mobile_number', errors, "point_of_contact") }
      </div>

      {/* Position */ }
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize">{ t('step6.position') }</label>
        <span className="p-float-label mb-1">
          <Controller name="point_of_contact[position]" control={ control }
            rules={ { required: activeStep === 5 ? ' Position required.' : false } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('position', errors, "point_of_contact") }
      </div>

      {/* email */ }
      <div className="col-12 col-md-6 field mb-3">
        <label className="mb-1 text-capitalize">{ t('step6.email') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="point_of_contact[Email_address]" control={ control }
            rules={ {
              required: activeStep === 5 ? 'Email is required.' : false,
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid Email Address!",
              },
            } }
            render={ ({ field, fieldState }) => (
              <InputText
                id={ field.name }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('Email_address', errors, "point_of_contact") }
      </div>
    </>
  )
}

