import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'; // Import ReactGA
import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import logo from "../../../../assets/img/logo.webp";

const _menuTabs = [
  {
    key: "home",
    path: "/",
    visible: false, // For the Sidebar
  },
  {
    key: "box_offices",
    path: "/box-offices",
    visible: true, // For the Sidebar
  },
  {
    key: "packages",
    path: "/packages",
    visible: false, // For the Sidebar
  },
  {
    key: "X-Artists",
    path: "/x-artist",
    visible: false, // For the Sidebar
  },
  {
    key: "careers",
    path: "/careers",
    visible: false, // For the Sidebar
  },
  {
    key: "vendor_registration",
    path: "/vendor",
    visible: false, // For the Sidebar
  },
];

function Menu() {
  const { t, i18n } = useTranslation("menu");
  const { activeMenuTab, setActiveMenuTab } = useClientGlobalContext();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const currentLanguage = i18n.language;
  const location = useLocation();
console.log(location,'location')
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const filteredObjects = _menuTabs.filter((obj) =>
      obj.path.includes(window.location.pathname)
    );
    setActiveMenuTab(filteredObjects[0]?.key);
  }, []);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const sideBarLogo = () => {
    return (
      <a className="navbar-brand me-auto" href="/">
        <img src={logo} className="img-fluid" alt="logo" width={150} />
      </a>
    );
  };
  const handleNavLinkClick = (key) => {
    setActiveMenuTab(key);
    if (key === "home") {
         ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Home Link'
      });
    }
  };

  return (
    <nav className={`navbar navbar-expand-lg navbar-dark bg_gradient  ${location.pathname !== '/' ? 'fixed-top' :  isFixed && location.pathname == '/'   ? 'fixed-top' : ''}  z-[10001]]`}>
        
      <div className="w-100 d-flex align-items-center py-3 px-md-4 mx-3 mx-lg-5">
        <i
          className="pi pi-bars me-4 fs-4 fw-bold cursor-pointer d-lg-none  "
          onClick={() => setVisibleLeft(true)}
        ></i>
        <Sidebar
          visible={visibleLeft}
          onHide={() => setVisibleLeft(false)}
          icons={sideBarLogo}
        >
          <ul className="navbar-nav ms-auto ">
            {_menuTabs.map((tap, index) => {
              return (
                <li
                  className={` nav-item nav-list text-[16px] lg:text-[18px] mx-lg-4 mx-2 fw-normal my-1  ${
                    !tap?.visible ? "d-lg-none" : ""
                  } `}
                  key={index}
                >
                  <NavLink
                    className={`${
                      tap?.key == activeMenuTab ? "active_md_tab" : ""
                    }  nav-link text-uppercase`}
                    to={tap?.path}
                    onClick={() => {
                      handleNavLinkClick(tap?.key);
                    }}
                  >
                    {t(`${tap?.key}`)}
                  </NavLink>
                </li>
              );
            })}
            <li
              className={` nav-item nav-list text-[16px] lg:text-[18px] mx-lg-4 mx-2 fw-normal my-1  `}
            >
              {currentLanguage === "en" ? (
                <button onClick={() => handleLanguageChange("ar")}>AR</button>
              ) : (
                <button onClick={() => handleLanguageChange("en")}>EN</button>
              )}
            </li>
          </ul>
        </Sidebar>

        <a className="navbar-brand" href="/">
          <img src={logo} className="img-fluid" alt="logo" width={150} />
        </a>

        <div className={`navbar-collapse d-none w-100`} id="navbarNav">
          <ul
            className={`navbar-nav ${
              i18n.language === "en" ? "ml-auto" : "mr-auto"
            } `}
          >
            {_menuTabs.map((tap, index) => {
              return (
                <li
                  className={`nav-item nav-list text-[14px] xl:text-[18px] mx-xl-4 mx-2  fw-normal`}
                  key={index}
                >
                  <NavLink
                    className={`${
                      tap?.key == activeMenuTab ? "active_tab" : ""
                    }  nav-link text-uppercase`}
                    to={tap?.path}
                    onClick={() => {
                     handleNavLinkClick(tap?.key);
                    }}
                  >
                    {t(`${tap?.key}`)}
                  </NavLink>
                </li>
              );
            })}
            {currentLanguage === "en" ? (
              <button onClick={() => handleLanguageChange("ar")}>AR</button>
            ) : (
              <button onClick={() => handleLanguageChange("en")}>EN</button>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
