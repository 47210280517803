import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { useGetCareerById } from '../../Queries'

import Layout from '../../Layout/Layout'

import { Accordion, AccordionTab } from 'primereact/accordion';

function CareerDetails () {
  const getCareerById = useGetCareerById();
  const { id } = useParams();

  const [careerInfo, setCareerInfo] = useState();

  const getData = async () => {
    await getCareerById.mutateAsync(id, {
      onSuccess: (data) => {
        setCareerInfo(data)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <Layout>
      <Accordion activeIndex={ 0 } className="mt-4">

        <AccordionTab header="Personal Information" className=''>
          <div className="d-flex flex-nowrap">
            <div className="col-6 d-flex me-2">
              <div className="col-6"></div>
              <div className="col-6"></div>
            </div>
            <div className="col-6 d-flex ms-2">
              <div className="col-6"></div>
              <div className="col-6"></div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab header="Commercial Information">
          <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
        </AccordionTab>

        <AccordionTab header="Bank Information">
          <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
        </AccordionTab>

      </Accordion>
    </Layout>
  )
}

export default CareerDetails