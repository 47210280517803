import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { formatDuration, useTitle } from '../../../config/global';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
import { useDeleteDataTableRow } from '../Queries';
import { useDataTableContext } from '../Contexts/DataTableContext';

import Layout from '../Layout/Layout';
 import { Image } from 'react-bootstrap';


function EventScheduleUsers () {
    useTitle('events');

    const { totalRecords, onPage, lazyParams, setLazyParams, data, dataHandler, getDataTable } = useDataTableContext();
    const { id } = useParams();

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "event_schedule_user",
            filters: {
                'event_schedule_id': { value: id, matchMode: 'equals' },
                // 'name': { value: '', matchMode: 'contains' },
                // 'email': { value: '', matchMode: 'contains' },
                // 'phone': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Link to setup page */ }
                <Tooltip target={ `.setup-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <NavLink to={ `/admin/event/schedule/${rowData.id}` } >
                    <button className={ `btn btn-sm btn-icon setup-button-${ rowData.id }` }
                        data-pr-position="bottom" data-pr-tooltip="Setup">
                        <i className="fa-solid fa-eye"></i>
                    </button>
                </NavLink>
            </div>
        );
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2 fs-4">Events List</h5>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable
                        lazy
                        paginator
                        rows={ lazyParams.rows }
                        value={ data }
                        first={ lazyParams.first }
                        onPage={ onPage }
                        onSort={ dataHandler }
                        filters={ lazyParams.filters }
                        dataKey="id"
                        loading={ getDataTable?.isLoading }
                        onFilter={ dataHandler }
                        sortField={ lazyParams.sortField }
                        className="table"
                        sortOrder={ lazyParams.sortOrder }
                        totalRecords={ totalRecords }
                        responsiveLayout="scroll"
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                    >
                        <Column field='name' header="Name" sortable  />
                        <Column field="email" header="Email" sortable  />
                        <Column field="phone" header="Phone" sortable  />
                
                    </DataTable>
                </div>
            </div>
        </Layout>
    );
}

export default EventScheduleUsers