import { useMutation,useQuery } from "react-query";
import axiosInstance from "../../../config/Axios";
import { useAdminGlobalContext } from "../Contexts/AdminGlobalContext";




//--------upload Video api-------------
const uploadVideo=async(payload)=>{
    const {data}=await axiosInstance.post('/setting/create',payload);

    return data.data
}


export const useUploadVideoMutation=()=>{
    const { showToast } = useAdminGlobalContext();
    return useMutation(uploadVideo,{
        onSuccess:(data)=>{
            showToast("success", "Upload Video", "Video uploaded successfully!")

        },
        onError:(error)=>showToast("error", "Video upload", error.response?.data?.message)
    })
}



