import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle } from '../../../config/global';
import { useDataTableContext } from '../Contexts/DataTableContext';
import Layout from '../Layout/Layout';
import { Button } from 'primereact/button';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
import PdfViewer from '../SharedComponents/PdfViewer';


function VendorIndex () {
    useTitle('events');

    const { totalRecords, onPage, lazyParams, setLazyParams, data, dataHandler, getDataTable } = useDataTableContext();
    const { dialogHandler, openDialog } = useAdminGlobalContext();
    const [selectedPDF, setSelectedPDF] = useState("");

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "vendors",
            filters: {
                'brand_name': { value: '', matchMode: 'contains' },
                'company_name': { value: '', matchMode: 'contains' },
                'scope_of_work': { value: '', matchMode: 'contains' },
                'vendor_type': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                <NavLink
                    to={ `/admin/vendors/${ rowData?.id }/details` }
                    className="submit_btn bg-secondary p-2 rounded text-black"
                    aria-label="details">
                    view details
                </NavLink>
            </div>
        );
    }

    const profileBodyTemplate = (rowData) => {
        return (
            <div className="text-primary underline cursor-pointer"
                onClick={ () => { previewHandler(rowData?.company_profile) } }
            >
                Preview
            </div>)
    }

    const previewHandler = (url) => {
        setSelectedPDF(url);
        dialogHandler("pdfViewer")
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2 fs-4">Vendors List</h5>
                </div>
                <div className='table-responsive text-nowrap'>
                    <DataTable
                        lazy
                        paginator
                        rows={ lazyParams.rows }
                        value={ data }
                        first={ lazyParams.first }
                        onPage={ onPage }
                        onSort={ dataHandler }
                        filters={ lazyParams.filters }
                        dataKey="id"
                        loading={ getDataTable?.isLoading }
                        onFilter={ dataHandler }
                        sortField={ lazyParams.sortField }
                        className="table"
                        sortOrder={ lazyParams.sortOrder }
                        totalRecords={ totalRecords }
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                    >
                        <Column field="brand_name" header="Brand Name" className='text-center' sortable filter filterPlaceholder="Search by Brand Name" />
                        <Column field="company_name" header="Company Name" className='text-center' sortable filter filterPlaceholder="Search by Company Name" />
                        <Column field="years_of_experience" header="Years of Experience" className='text-center' sortable />
                        <Column field="scope_of_work" header="Scope of Work" className='text-center' sortable filter filterPlaceholder="Search by Company Name" />
                        <Column field="vendor_type" header="Vendor Type" className='text-center' sortable filter filterPlaceholder="Search by Vendor Type" />
                        <Column body={ profileBodyTemplate } field="company_profile" header="Company Profile" className='text-center' sortable filter filterPlaceholder="Search by Vendor Type" />
                        <Column field="created_at" header="Created Date" className='text-center' sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>
            </div>

            { openDialog.pdfViewer ? <PdfViewer pdfURL={ selectedPDF } /> : <></> }
        </Layout>
    );
}

export default VendorIndex