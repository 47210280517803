const rotationOptions = [
    { label: "0ْ ", value: "0" },
    { label: "90ْ ", value: "90" },
    { label: "180ْ ", value: "180" },
    { label: "270 ", value: "270" },
];

// ReactPrime file input options 
const chooseOptions = {
    className: 'file_input',
    label: 'click to upload image...',
    icon: 'pi pi-upload d-none',
    style: {
        background: 'black',
        color: 'gray',
        width: "100%",
        border: "1px dashed #D9DEE3",
        borderRadius: "20px",
        fontWeight: "normal",
        fontSize: "16px",

    }
};

const deviceStatusOptions = [
    { name: 'Active', code: 'ACTIVE' },
    { name: 'Inactive', code: 'INACTIVE' },
];

// DataTable Config
const adsConfig =
{
    modelName: "advertisements",
    filters: {
        'title': { value: '', matchMode: 'contains' },
        'duration': { value: '', matchMode: 'contains' },
        'time_slot_filling': { value: '', matchMode: 'contains' },
    }
}

const archiveAdsConfig =
{
    modelName: "ads_schedule",
    filters: {
        'status': { value: 'ARCHIVED', matchMode: 'equals' },
        'repetition': { value: '', matchMode: 'contains' },
        'frequency': { value: '', matchMode: 'contains' },
    }
}

const _deviceGroupKey = ["all", "devices", "groups"]

const scopeOfWorkOptions = [
    { label: 'Build-up and Production', value: 'Build-up and Production' },
    { label: 'Fit-out & Interior Design', value: 'Fit-out & Interior Design' },
    { label: 'Crowd Management', value: 'Crowd Management' },
    { label: 'Catering', value: 'Catering' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Logistics Services', value: 'Logistics Services' },
    { label: 'Power Supply', value: 'Power Supply' },
    { label: 'Marketing & PR', value: 'Marketing & PR' },
    { label: 'Health and Safety', value: 'Health and Safety' },
    { label: 'Workforce', value: 'Workforce' },
    { label: 'Printing and Branding', value: 'Printing and Branding' },
    { label: 'Engineering Office', value: 'Engineering Office' },
    { label: 'Governmental Relations', value: 'Governmental Relations' },
    { label: 'AVL & Technology Services', value: 'AVL & Technology Services' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Activations', value: 'Activations' },
    { label: 'Costume Design & Sewing', value: 'Costume Design & Sewing' },
    { label: 'Talent Agency', value: 'Talent Agency' },
    { label: 'Fashion Design', value: 'Fashion Design' },
    { label: 'Event Services', value: 'Event Services' },
    { label: 'Others...', value: 'Others' },
]
 

export {
    // dropdown options
    deviceStatusOptions,
    scopeOfWorkOptions,
    rotationOptions,
    chooseOptions,

    // DataTable Configuration Data
    archiveAdsConfig,
    adsConfig,

    _deviceGroupKey,
}