import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext'

function PdfViewer ({ pdfURL }) {
    const { dialogHandler, openDialog } = useAdminGlobalContext();

    const onHide = () => {
        dialogHandler("pdfViewer")
    }

    return (
        <Dialog visible={ openDialog.pdfViewer }
            style={ { width: '50%' ,height:"100vh"} }
            breakpoints={ { '960px': '95vw' } }
            header="PDF Viewer" modal
            onHide={ onHide }>
            <div className=" h-100">
                <iframe
                    src={ pdfURL }
                    title="PDF Viewer"
                    width="100%"
                    height="100%"
                    style={ { border: 'none' } }
                />
            </div>
        </Dialog>
    )
}

export default PdfViewer