import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form'
import moment from 'moment';
import _ from 'lodash';
import Aos from 'aos';

import { Steps } from 'primereact/steps';
import Layout from '../Layout/Layout'
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';

import { useClientGlobalContext } from '../Contexts/ClientGlobalContext';
import { useVendorRegistrationMutation } from '../Queries/vendor';
import { useTitle } from '../../../config/global';
import Step7 from './components/Step7';

function Vendor () {
    useTitle('Vendor Registration');

    const { formState: { errors }, handleSubmit, control, setValue, reset } = useForm();
    const { vendorFormFiles, activeStep, setActiveStep, vendorType } = useClientGlobalContext();
    const vendorMutation = useVendorRegistrationMutation();
    const { t, i18n } = useTranslation("vendor");
    const [steps, setSteps] = useState([]);

    let localSteps = [
        { label: t(`steps.local.step_1`) },
        { label: t(`steps.local.step_2`) },
        { label: t(`steps.local.step_3`) },
        { label: t(`steps.local.step_4`) },
        { label: t(`steps.local.step_5`) },
        { label: t(`steps.local.step_6`) },
    ]

    let foreignSteps = [
        { label: t(`steps.foreign.step_1`) },
        { label: t(`steps.foreign.step_2`) },
        { label: t(`steps.foreign.step_3`) },
        { label: t(`steps.foreign.step_4`) },
    ]

    useEffect(() => {
        setSteps( vendorType === "local" ? localSteps : foreignSteps)
    }, [vendorType])


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const appendToFormData = (formData, source, prefix = '') => {
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                let value = ""

                if (key === "validity")
                    value = moment(source[key]).format('YYYY-MM-DD');
                else
                    value = source[key];

                if (value !== undefined && value !== null) {
                    const fullKey = prefix ? `${ prefix }[${ key }]` : key;
                    if (typeof value === 'object' && !Array.isArray(value)) {
                        appendToFormData(formData, value, fullKey);
                    } else {
                        if (fullKey.includes("validity")) {
                            const formattedValue = moment(value).format('YYYY-MM-DD');
                            formData.append(fullKey, formattedValue);
                        } else if (!fullKey.includes("attachment")) {
                            formData.append(fullKey, value);
                        }
                    }
                }
            }
        }
    };

    const submitForm = async (data) => {
        const formData = new FormData()

        formData.append("commercial_registration[attachment]", vendorFormFiles?.commercial_registration_attachment)
        formData.append("bank_information[attachment]", vendorFormFiles?.bank_certification_attachment)
        formData.append("company_profile", vendorFormFiles?.company_profile_attachment)

        if (vendorType === "local") {
            formData.append("vat[attachment]", vendorFormFiles?.vat_attachment)
            formData.append("gosi[attachment]", vendorFormFiles?.gosi_attachment)
            formData.append("riyadh_chamber_membership[attachment]", vendorFormFiles?.chamber_membership_attachment)
            formData.append("commercial_activity_license[attachment]", vendorFormFiles?.activity_license_attachment)
            formData.append("zakat_tax_customs_authority_certificate[attachment]", vendorFormFiles?.zakat_attachment)
            formData.append("national_address[attachment]", vendorFormFiles?.address_attachment)
        }

        appendToFormData(formData, data);
        
        formData.append("vendor_type", vendorType.toUpperCase())

        await vendorMutation.mutateAsync(formData, {
            onSuccess: () => {
                setActiveStep(7)
            }
        });
    }

    const validateStepData = () => {
        setActiveStep(prev => prev + 1)
    }

    return (
        <Layout>
            <div data-aos="fade-down" className="stepsFont flex flex-md-row flex-column items-center justify-center w-100 px-2 px-lg-4 steps mt-[100px] md:mt-[60px] mb-5 mb-md-0" style={ { minHeight: "750px", fontFamily: 'Montserrat' } }>
                {
                    activeStep > 5 ?
                        <Step7 reset={ reset } />
                        :
                        <>
                            <div className="col-md-2 col-12 stepsFont">
                                <Steps
                                    model={ vendorType.toLowerCase() === "local" ? localSteps : foreignSteps }
                                    activeIndex={ activeStep }
                                    onSelect={ (e) => e.index < activeStep ? setActiveStep(e.index) : "" }
                                    readOnly={ false }
                                    className="w-100 h-100" />

                            </div>
                            <div className="col-12 mx-2 mx-md-0 col-lg-8 ">
                                <div className='career-form-container effect-3d-shadow rounded-[40px]'  >
                                    <form className='py-5 px-3 px-md-5 d-flex flex-row flex-wrap justify-start align-between h-100' onSubmit={ handleSubmit(submitForm) }>
                                        { activeStep === 0 ? <Step1 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                        { activeStep === 1 ? <Step2 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                        {
                                            vendorType === "local" ?
                                                <>
                                                    { activeStep === 2 ? <Step3 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                                    { activeStep === 3 ? <Step4 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                                    { activeStep === 4 ? <Step5 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                                    { activeStep === 5 ? <Step6 errors={ errors } control={ control } /> : <></> }
                                                </>
                                                :
                                                <>
                                                    { activeStep === 2 ? <Step4 errors={ errors } control={ control } setValue={ setValue } /> : <></> }
                                                    { activeStep === 3 ? <Step6 errors={ errors } control={ control } /> : <></> }
                                                </>
                                        }

                                        <div className="order-last col-12 field d-flex flex-nowrap justify-end align-self-end mt-5">
                                            {
                                                activeStep > 0 ?
                                                    <div className="col-6 pe-1">
                                                        <button
                                                            onClick={ () => setActiveStep(prev => prev - 1) }

                                                            type='button'
                                                            className='w-100 text-center fw-bold fs-5 p-2 px-5 bg-transparent border-2 rounded-[20px] btn-shadow transform transition-transform duration-300 hover:-translate-y-1'>Back</button>
                                                    </div> :
                                                    <></>
                                            }
                                            <div className="col-6 ps-1">
                                                {
                                                    activeStep === steps?.length - 1 ?
                                                        <button type='submit' className='w-100 text-center fw-bold fs-5 p-2 px-5 bg-black border-2 border-black rounded-[20px] btn-shadow transform transition-transform duration-300 hover:-translate-y-1'>Register</button>
                                                        :
                                                        <button
                                                            onClick={ handleSubmit(validateStepData) }
                                                            type='button'
                                                            className='w-100 text-center fw-bold fs-5 p-2 px-5 bg-black border-2 border-black rounded-[20px] btn-shadow transform transition-transform duration-300 hover:-translate-y-1'>Next</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </> }
            </div>
        </Layout>
    )
}

export default Vendor