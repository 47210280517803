import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle } from '../../../config/global';
import { useDataTableContext } from '../Contexts/DataTableContext';
import Layout from '../Layout/Layout';
 

function EventSchedule () {
    useTitle('events');

    const { totalRecords, onPage, lazyParams, setLazyParams, data, dataHandler, getDataTable, setId } = useDataTableContext();
    const { id } = useParams();

    useEffect(() => {
        setId(id)
        setLazyParams(prev => ({
            ...prev,
            modelName: "event_schedule",
            filters: {
                'area': { value: '', matchMode: 'contains' },
                'price': { value: '', matchMode: 'contains' },
                'date': { value: '', matchMode: 'contains' },
                'time': { value: '', matchMode: 'contains' },
                'sets': { value: '', matchMode: 'contains' },
                'event_id': { value: id, matchMode: 'equals' },
                'sets_count': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                <Tooltip target={ `.setup-button-${ rowData.event_schedule_id }` } showDelay={ 100 } className="fs-8" />
                <NavLink to={ `/admin/event/schedule/${ rowData.event_schedule_id }/users` } >
                    <button className={ `btn btn-sm btn-icon setup-button-${ rowData.event_schedule_id }` }
                        data-pr-position="bottom" data-pr-tooltip="Setup">
                        <i className="fa-solid fa-eye"></i>
                    </button>
                </NavLink>

            </div>
        );
    }

    const timeBodyTemplate = (rowData) => {
        return rowData?.time?.replace("_", " ")?.toLowerCase();
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2 fs-4 text-capitalize">{ data?.[0]?.name_en } schedule </h5>
                </div>
                <div className='table-responsive text-nowrap'>
                    <DataTable
                        lazy
                        paginator
                        rows={ lazyParams.rows }
                        value={ data }
                        first={ lazyParams.first }
                        onPage={ onPage }
                        onSort={ dataHandler }
                        filters={ lazyParams.filters }
                        dataKey="id"
                        loading={ getDataTable?.isLoading }
                        onFilter={ dataHandler }
                        sortField={ lazyParams.sortField }
                        className="table"
                        sortOrder={ lazyParams.sortOrder }
                        totalRecords={ totalRecords }
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                    >
                        <Column field="area" header="Area" className='text-center' sortable filter filterPlaceholder="search..." />
                        <Column field="price" header="Price (SAR)" className='text-center' sortable filter filterPlaceholder="search..." />
                        <Column field="date" header="Date" className='text-center' sortable filter filterPlaceholder="search..." />
                        <Column body={ timeBodyTemplate } header="Time" className='text-center text-capitalize' sortable filter filterPlaceholder="search..." />
                        <Column field="sets" header="Seats" className='text-center' sortable />
                        <Column field="sets_count" header="Seats Count" className='text-center' sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>
            </div>
        </Layout>
    );
}

export default EventSchedule