import React from "react";
import { useTranslation } from "react-i18next";
import animation from "../../../assets/img/animation/about_us/About us/Vision/Vision-crop.gif";
import animation1 from "../../../assets/img/animation/about_us/About us/Mission/Win-crop.gif";
import { useTitle } from "../../../config/global";

import Layout from "../Layout/Layout";

function AboutUs () {
  useTitle('About-us');
  const { t } = useTranslation("About_us");
  
  return (
    <Layout>
      <div className="headerImage">
        <div className="max-w-5xl mx-auto p-2 mt-16 relative">
          <div className="text-center flex flex-col items-center justify-center w-full">
            <h1 className="text-3xl md:text-4xl lg:text-5xl mt-10 font-bold text-yellow">
              {t("title")}
            </h1>
            <p className="text-base md:text-lg mt-10 w-[80%] text-center">
              {t("discotion")}
            </p>
          </div>

          <div className="max-w-3xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-5 mt-20">
            <div className="relative flex flex-col items-start justify-between overflow-hidden rounded-3xl border border-white bg-gradient-to-t from-black to-white">
              <div
                className="h-full p-3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
              >
                <div className="flex flex-col items-center justify-center w-full mb-4">
                  <i className="fa-3x fas fa-pencil-ruler text-yellow"></i>
                  <h1 className="text-xl mt-3">{t("cards.card-1.title")}</h1>
                </div>
                <span className="relative text-xs md:text-sm text-center dark:text-gray-400">
                  {t("cards.card-1.discotion")}
                </span>
              </div>
            </div>
            <div className="relative flex flex-col items-start justify-between overflow-hidden rounded-3xl border border-white bg-gradient-to-t from-black to-white">
              <div
                className="h-full p-3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
              >
                <div className="flex flex-col items-center justify-center w-full mb-4">
                  <i className="fa-3x fas fa-shapes text-yellow"></i>
                  <h1 className="text-xl mt-3">{t("cards.card-2.title")}</h1>
                </div>
                <span className="relative text-xs md:text-sm text-center dark:text-gray-400">
                  {t("cards.card-2.discotion")}
                </span>
              </div>
            </div>
            <div className="relative flex flex-col items-start justify-between overflow-hidden rounded-3xl border border-white bg-gradient-to-t from-black to-white">
              <div
                className="h-full p-3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
              >
                <div className="flex flex-col items-center justify-center w-full mb-4">
                  <i className="fa-3x fas fa-handshake text-yellow"></i>
                  <h1 className="text-xl mt-3">{t("cards.card-3.title")}</h1>
                </div>
                <span className="relative text-xs md:text-sm text-center dark:text-gray-400">
                  {t("cards.card-3.discotion")}
                </span>
              </div>
            </div>
          </div>
            {" "}
          

          <div className="mt-20 w-full flex flex-col lg:flex-row lg:items-center relative">
            <div className="flex-1 p-10 bg-black bg-opacity-60 rounded-xl text-center lg:ml-10">
              <h1 className="text-3xl md:text-4xl font-bold text-yellow">
                {t("vision-title")}
              </h1>
              <p className="text-base md:text-lg mt-3">{t("vision")}</p>
            </div>
            <img
              className="absolute xl:top-[20%] xl:left-[-10%] lg:w-[7rem] lg:top-[-30%] lg:left-[5%] xl:w-[10rem] md:w-[15%] md:bottom-[50%] md:left-[10%] mb-10 lg:mb-0 hide-on-tablet"
              src={animation}
              alt="Vision Animation"
            />
          </div>
            {" "}
          
          <div className="mt-10 w-full flex flex-col lg:flex-row-reverse lg:items-center relative">
            <div className="flex-1 p-10 bg-black bg-opacity-60 rounded-xl text-center lg:mr-10">
              <h1 className="text-3xl md:text-4xl font-bold text-yellow">
                {t("mission-title")}
              </h1>
              <p className="text-base md:text-lg mt-3">{t("mission")}</p>
            </div>
            <img
              className="absolute xl:right-[-20%] xl:top-[10%] xl:w-[20%] lg:right-[3%] lg:bottom-[50%] lg:w-[15%] md:w-[20%] md:bottom-[60%] md:right-[10%] mb-10 lg:mb-0 hide-on-tablet"
              src={animation1}
              alt="Mission Animation"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
