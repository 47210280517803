import { useMutation, useQuery } from 'react-query';

import { useClientGlobalContext } from '../Contexts/ClientGlobalContext';
import axiosInstance from '../../../config/Axios';

// -------------------------Create Career API-------------------------//
const createCareer = async (payload) => {
    const { data } = await axiosInstance.post("/career/create", payload);

    return data.data;
}

export const useCareerMutation = () => {
    const { showToast } = useClientGlobalContext();

    return useMutation(createCareer, {
        onSuccess: (data) => showToast("success", "Career", "Applied successfully!"),
        onError: (error) => showToast("error", "Career", error.response?.data?.message)
    })
}

