import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';

import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { FileUpload } from 'primereact/fileupload';

import { useAdminGlobalContext } from '../../Contexts/AdminGlobalContext'
import { useEditEventMutation } from '../../Queries';
import { getFormErrorMessage } from '../../../../config/global';
import { chooseOptions } from '../../../../config/constants';
import _ from 'lodash';


function EditDialog ({ selectedEvent }) {
    const { formState: { errors }, handleSubmit, control, setValue, reset } = useForm();
    const { openDialog, dialogHandler } = useAdminGlobalContext();
    const [poster, setPoster] = useState({});
    const editEvent = useEditEventMutation()
    const posterUploadRef = useRef(null);

    useEffect(() => {
        reset(selectedEvent)
    }, [reset])

    // Cancel selected file 
    const onCancelUpload = () => {
        setValue('poster', null, { shouldValidate: true });
        setPoster({})
        posterUploadRef.current.clear();
    };

    const handleFileUpload = (e, onChange) => {
        setPoster(e.files[0])
        const file = e.files[0];
         onChange(file);
        setValue('poster', file, { shouldValidate: true });
    };

    const submitForm = async (data) => {
        var formData = new FormData();
         if (!_.isNull(poster))
            formData.append("poster", poster);
        
        formData.append("event_id", selectedEvent?.id);
        formData.append("location", data?.location);
        formData.append("name_ar", data?.name_ar);
        formData.append("name_en", data?.name_en);


        await editEvent.mutateAsync(formData)
    };

    return (
        <Dialog
            visible={ openDialog.editEvent }
            style={ { width: '50%' } }
            breakpoints={ { '960px': '95vw' } }
            header="Edit Event"
            modal
            onHide={ () => dialogHandler("editEvent") }>
            <form action="" className='p-3 d-flex flex-column' onSubmit={ handleSubmit(submitForm) }>
                <div className="col-12 d-flex flex-wrap mb-3">
                    {/*en name */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">En Name </label>
                        <span className="p-float-label mb-1">
                            <Controller name="name_en" control={ control }
                                rules={ { required: 'EN Name is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        autoFocus
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name_en', errors) }
                    </div>

                    {/* ar name */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Ar Name </label>
                        <span className="p-float-label mb-1">
                            <Controller name="name_ar" control={ control }
                                rules={ { required: 'AR Name is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        autoFocus
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name_ar', errors) }
                    </div>

                    {/* location */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Location</label>
                        <span className="p-float-label mb-1">
                            <Controller name="location" control={ control }
                                rules={ { required: 'location is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('location', errors) }
                    </div>

                    {/* Poster */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Event Poster</label>
                        <span className="p-float-label mb-1">
                            <Controller name="poster" control={ control }
                                rules={ { required: false } }
                                render={ ({ onChange, onBlur, field, fieldState }) => (
                                    <FileUpload
                                        id={ field.name }
                                        { ...field }
                                        mode="basic"
                                        name="poster"
                                        accept=""
                                        maxFileSize={ 1000000000 }
                                        customUpload
                                        ref={ posterUploadRef }
                                        onSelect={ (e) => handleFileUpload(e, onChange) }
                                        onClick={ onCancelUpload }
                                        chooseOptions={ chooseOptions }
                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        icon="none"
                                    />
                                ) } />
                        </span>
                        { getFormErrorMessage('poster', errors) }
                    </div>
                </div>
                <div className="field mt-3 col-12 d-flex justify-center">
                    <button
                        type="button"
                        className='cancel_btn text-center fw-bold px-5 py-2 bg-secondary rounded-2 btn-shadow me-3 '
                        onClick={ () => dialogHandler("editEvent") }

                    >Cancel</button>
                    <button
                        type="submit"
                        className='submit_btn text-center fw-bold px-5 py-2 bg-black rounded-2 btn-shadow'>
                        Update
                    </button>
                </div>
            </form>

        </Dialog >
    )
}

export default EditDialog