import React from "react";

import Layout from "../Layout/Layout";

import Headers from "./Component/Headers";
import Services from "./Component/Services";
import FeaturedEvents from "./Component/FeaturedEvents";
import ContactUs from "./Component/ContactUs";
import WorkWithUs from "./Component/WorkWithUs";
import GoogleAds from './Component/GoogleAds'
import { useTitle } from "../../../config/global";

const Landing = () => {
  useTitle('Home');

  return (
    <Layout>
      <Headers />
      <FeaturedEvents />
      <Services />
      <ContactUs />
      <WorkWithUs />
      {/* <GoogleAds adClient="ca-pub-4069305174874358" adSlot="6644457795"  /> */}
    </Layout>
  );
};

export default Landing;
