import _, { isEmpty } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import axiosInstance from '../../../config/Axios';

import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';


// -------------------------Get Profile-------------------------//
const getProfile = async () => {
    const { data } = await axiosInstance.get(`/users/show`);

    return data.data
}

export const useGetProfile = () => {
    const { showToast } = useAdminGlobalContext();

    let { isLoading, data, error, isError } = useQuery('getProfile', getProfile);

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { isLoading, data };
}

// -------------------------Update Profile-------------------------//
const updateProfile = async (payload) => {
    var formData = new FormData();

    formData.append("name", payload?.name);
    formData.append("phone", payload?.phone);

    if (!_.isNull(payload?.newImage))
        formData.append("profile_img", payload?.newImage);

    const { data } = await axiosInstance.post(`/users/update`, formData);

    return data.data
}

export const useUpdateProfileMutation = () => {
    const { showToast, setDisableBtn, setProfileImage } = useAdminGlobalContext();
  
    return useMutation(updateProfile, {
        onSuccess: async (data) => {
            setProfileImage(data?.image)
            localStorage.setItem("name", data?.name);
            localStorage.setItem("image", data?.image);
            localStorage.setItem("phone", data?.phone);
            showToast("success", "Update Profile", "Profile Updated Successfully!")
            setDisableBtn(false)
        },
        onError: (error) => {
            setDisableBtn(false)
            showToast("error", "Update Profile", error?.response?.data?.message)
        }
    })
}
