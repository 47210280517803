import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useCareerStatusHandler, useGetDataTable } from '../../Queries';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

function CareersDataTable () {
    const getDataTable = useGetDataTable();
const updateStatus = useCareerStatusHandler()
     let loadLazyTimeout = null;

    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState();
    const [lazyParams, setLazyParams] = useState({
        modelName: "careers",
        first: 0,
        rows: 5,
        page: 0,
        sortField: 'id',
        filters: {
            'fullName': { value: '', matchMode: 'contains' },
            'email': { value: '', matchMode: 'contains' },
            'position': { value: '', matchMode: 'contains' },
            'degree': { value: '', matchMode: 'contains' },
            'career_type': { value: '', matchMode: 'contains' },
            'experience': { value: '', matchMode: 'contains' },
            'package': { value: '', matchMode: 'contains' },
            'status': { value: 'PENDING', matchMode: 'equals' },
        }
    });

    const onPage = (event) => {
        event['modelName'] = lazyParams.modelName;
        setLazyParams(event);
    }

    const dataHandler = (event) => {
        event['modelName'] = lazyParams.modelName;
        event['first'] = 0;
        event['page'] = 0;

        setLazyParams(event);
    }

    const loadLazyData = () => {
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(async () => {
            await getDataTable.mutateAsync(lazyParams, {
                onSuccess: async (data) => {
                    setTotalRecords(data.pagination.total);
                    setData(data.data);
                }
            })
        }, Math.random() * 1000 + 250);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        return () => {
            setData([])
            setTotalRecords(0)
        }
    }, [])

    // Data Table Body Template
    const updateHandler = async (rowData, status) => {
        await updateStatus.mutateAsync({
            id: rowData?.id,
            data: {
                status: status
            }
        }, {
            onSuccess: () => loadLazyData()
        })
    }

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Approve Request" onClick={ () => {updateHandler(rowData, "APPROVE") } }><i className="fs-4 text-success far fa-check-circle me-2"></i></button>

                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
               Reject Request" onClick={ () => {updateHandler(rowData, "REJECTED")} }><i className="fs-4 text-danger far fa-times-circle me-2"></i></button>

                <NavLink
                    to={ `/admin/careers/${ rowData?.id }/details` }
                    className="submit_btn bg-secondary p-2 rounded text-black"
                    aria-label="details">
                    view details
                </NavLink>
            </div>
        );
    }

    return (
        <div className='table-responsive text-nowrap'>
            <DataTable
                lazy
                paginator
                rows={ lazyParams.rows }
                value={ data }
                first={ lazyParams.first }
                onPage={ onPage }
                onSort={ dataHandler }
                filters={ lazyParams.filters }
                dataKey="id"
                loading={ getDataTable?.isLoading }
                onFilter={ dataHandler }
                sortField={ lazyParams.sortField }
                className="table"
                sortOrder={ lazyParams.sortOrder }
                totalRecords={ totalRecords }
                responsiveLayout="scroll"
                rowsPerPageOptions={ [15, 25, 50, 100] }
            >
                <Column field="fullName" header="Full Name" className='text-center' sortable />
                <Column field="email" header="Email" className='text-center' sortable />
                <Column field="mobile" header="Mobile" className='text-center' sortable />
                <Column field="position" header="Position" className='text-center' sortable />
                <Column field="career_type" header="Career Type" className='text-center text-capitalize' sortable />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
            </DataTable>
        </div>)
}

export default CareersDataTable