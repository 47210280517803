import React, { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import { useAdminGlobalContext } from '../../Contexts/AdminGlobalContext'
import { getFormErrorMessage } from '../../../../config/global';
import { useCreateEventMutation } from '../../Queries';
import { FileUpload } from 'primereact/fileupload';
import { chooseOptions } from '../../../../config/constants';
import { useState } from 'react';
import moment from 'moment';

function CreateDialog () {
    const { formState: { errors }, handleSubmit, control, setValue } = useForm();
    const { openDialog, dialogHandler } = useAdminGlobalContext();
    const [poster, setPoster] = useState({});
    const createEvent = useCreateEventMutation()
    const posterUploadRef = useRef(null);

    // Cancel selected file 
    const onCancelUpload = () => {
        setPoster({})
        setValue('poster', null, { shouldValidate: true });
        posterUploadRef.current.clear();
    };

    const handleFileUpload = (e, onChange) => {
        setPoster(e.files[0])
        const file = e.files[0];
        onChange(file);
        setValue('poster', file, { shouldValidate: true });
    };

    const submitForm = async (data) => {
        var formData = new FormData();

        formData.append("poster", poster);
        formData.append("start_date", moment(data?.start_date).format("Y-M-DD"));
        formData.append("end_date", moment(data?.end_date).format("Y-M-DD"));
        formData.append("location", data?.location);
        formData.append("name_ar", data?.name_ar);
        formData.append("name_en", data?.name_en);
        formData.append("time", "BOTH");

        await createEvent.mutateAsync(formData, {
            onSuccess: () => { }
        })
    };

    return (
        <Dialog
            visible={ openDialog.createEvent }
            style={ { width: '50%' } }
            breakpoints={ { '960px': '95vw' } }
            header="Create Event"
            modal
            onHide={ () => dialogHandler("createEvent") }>
            <form action="" className='p-3 d-flex flex-column' onSubmit={ handleSubmit(submitForm) }>
                <div className="col-12 d-flex flex-wrap mb-3">
                    {/*en name */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">En Name </label>
                        <span className="p-float-label mb-1">
                            <Controller name="name_en" control={ control }
                                rules={ { required: 'EN Name is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        autoFocus
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name_en', errors) }
                    </div>

                    {/* ar name */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Ar Name </label>
                        <span className="p-float-label mb-1">
                            <Controller name="name_ar" control={ control }
                                rules={ { required: 'AR Name is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        autoFocus
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name_ar', errors) }
                    </div>

                    {/* star_date */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Start Date</label>
                        <span className="p-float-label mb-1 me-3">
                            <Controller name="start_date" control={ control }
                                rules={ { required: 'Start date is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <Calendar
                                        minDate={ new Date }
                                        id={ field.name }
                                        { ...field }
                                        inputRef={ field.ref }
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('start_date', errors) }
                    </div>

                    {/* end_date */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">End Date</label>
                        <span className="p-float-label mb-1 me-3">
                            <Controller name="end_date" control={ control }
                                rules={ { required: 'This field is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <Calendar
                                        minDate={ new Date }
                                        { ...field }
                                        id={ field.name }
                                        inputRef={ field.ref }
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('end_date', errors) }
                    </div>

                    {/* location */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Location</label>
                        <span className="p-float-label mb-1">
                            <Controller name="location" control={ control }
                                rules={ { required: 'location is required.' } }
                                render={ ({ field, fieldState }) => (
                                    <InputText
                                        id={ field.name }
                                        { ...field }
                                        className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                ) } />
                        </span>
                        { getFormErrorMessage('location', errors) }
                    </div>

                    {/* Poster */ }
                    <div className="col-12 col-lg-6 field pe-lg-4 mb-4">
                        <label className="mb-1 text-capitalize">Event Poster</label>
                        <span className="p-float-label mb-1">
                            <Controller name="resume" control={ control }
                                rules={ { required: 'Poster is required.' } }
                                render={ ({ onChange, onBlur, field, fieldState }) => (
                                    <FileUpload
                                        id={ field.name }
                                        { ...field }
                                        mode="basic"
                                        name="poster"
                                        accept="image/.jpg, image/.jpeg, image/.png"
                                        maxFileSize={ 1000000000 }
                                        customUpload
                                        ref={ posterUploadRef }
                                        onSelect={ (e) => handleFileUpload(e, onChange) }
                                        onClick={ onCancelUpload }
                                        chooseOptions={ chooseOptions }
                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        icon="none"
                                    />
                                ) } />
                        </span>
                        { getFormErrorMessage('poster', errors) }
                    </div>
                </div>
                <div className="field mt-3 col-12 d-flex justify-center">
                    <button
                        type="button"
                        className='cancel_btn text-center fw-bold px-5 py-2 bg-secondary rounded-2 btn-shadow me-3 '
                        onClick={ () => dialogHandler("createEvent") }

                    >Cancel</button>
                    <button type="submit" className='submit_btn text-center fw-bold px-5 py-2 bg-black rounded-2 btn-shadow'>Create</button>
                </div>
            </form>

        </Dialog >
    )
}

export default CreateDialog