import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import logo from "../../../../assets/img/logo.webp";

function Footer() {
  const { setActiveMenuTab } = useClientGlobalContext();
  const { t } = useTranslation("menu");

  const currentYear = new Date().getFullYear();

  return (
    <div className="w-100 bg-black border-t-[.1px] border-[#ffffff66] py-5  ">
      <div className="d-flex flex-wrap py-3 px-md-4 mx-3 mx-lg-5 ">
        <div className="col-12 col-sm-6 col-lg-3 mt-3  mt-sm-0">
          <img
            src={logo}
            className="img-fluid my-auto mb-2 w-[50%] lg:w-[70%]"
            alt="logo"
            width={228}
          />
          <p className="text-uppercase text-[13px]  ">
            &copy;  {currentYear} {t("rights_reserved")}
          </p>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-2 sm:mb-0 fw-semibold text-uppercase d-flex flex-column">
          <NavLink className="mb-2 sm:mb-3" to="/about-us" onClick={() => { setActiveMenuTab("about-us"); }}>
            {t("about_us")}
          </NavLink>
          <NavLink to="/services" onClick={() => { setActiveMenuTab("services"); }} >
            {t("services")}
          </NavLink>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-2 sm:mb-0 fw-semibold text-uppercase d-flex flex-column">
          <NavLink className="mb-2 sm:mb-3" to="/box-offices" onClick={() => { setActiveMenuTab("box_offices"); }} >
            {t("box_offices")}
          </NavLink>
          <NavLink to="/careers" onClick={() => { setActiveMenuTab("careers"); }}  >
            {t("careers")}
          </NavLink>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-2 sm:mb-0 fw-semibold text-uppercase d-flex flex-column">
          <NavLink className="mb-2 sm:mb-3" to="/vendor" onClick={() => { setActiveMenuTab("vendor registration"); }} >
            {t("vendor")}
          </NavLink>
          <NavLink to="/packages" onClick={() => { setActiveMenuTab("packages"); }}  >
            {t("packages")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Footer;
