import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../config/Axios';

import { useAdminGlobalContext } from '../../Admin/Contexts/AdminGlobalContext';

const login = async (formData) => {
    const { data } = await axiosInstance.post("/admin/login", formData);

    return data.data;
}

export const useLogInMutation = () => {
    const { showToast } = useAdminGlobalContext();
    const navigate = useNavigate();

    return useMutation(login, {
        onSuccess: async (data) => {
            if (data?.type === "ADMIN") {
                localStorage.setItem("name", data?.name);
                localStorage.setItem("email", data?.email);
                localStorage.setItem("image", data?.image);
                localStorage.setItem("phone", data?.phone);
                localStorage.setItem("token", data?.token);

                navigate("/admin/dashboard");
            } else {
                showToast("error", "Login Credential", 'Unauthorized!')
            }
        },
        onError: () => showToast("error", "Login Credential", "Invalid Username Or Password!")
    })
}

const logout = async () => {
    const { data } = await axiosInstance.get("/admin/logout");

    return data.data;
}

export const useLogOutMutation = () => {
    const { showToast } = useAdminGlobalContext();
    const navigate = useNavigate();

    return useMutation(logout, {
        onSuccess: async (data) => {
            localStorage.clear();
            navigate("/");
        },
        onError: (error) => showToast("error", "Logout ", error.response?.data?.message)
    })
}

// Forget Password
const forgetPassword = async (formData) => {
    const { data } = await axiosInstance.post("/users/forget-password", formData);

    return data.data;
}

export const useForgetPassMutation = () => {
    const { showToast } = useAdminGlobalContext();

    return useMutation(forgetPassword, {
        onSuccess: async (data) => {

        },
        onError: (error) => showToast("error", "Logout ", error.response?.data?.message)
    })
}

// Reset Password
const resetPassword = async (formData) => {
    const { data } = await axiosInstance.post(`/users/reset-password/${ formData.token }`, formData);

    return data.data;
}

export const useResetPassMutation = () => {
    const { showToast } = useAdminGlobalContext();
    const navigate = useNavigate();

    return useMutation(resetPassword, {
        onSuccess: async (data) => {
            showToast('success', 'Reset Password', data?.msg)
            navigate("/");
        },
        onError: (error) => showToast('error', 'Reset Password', error.response?.data?.message)
    })
}