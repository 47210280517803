import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../config/Axios';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
 
// -------------------------Update Vendor Request-------------------------//
const vendorStatusHandler = async (payload) => {
    const { data } = await axiosInstance.put(`/admin/vendor/status/${payload?.id}`, payload?.data);

    return data.data
}

export const useVendorStatusHandler = () => {
    const { showToast, setDisableBtn } = useAdminGlobalContext();
  
    return useMutation(vendorStatusHandler, {
        onSuccess: async (data) => {
            setDisableBtn(false)
            showToast("success", "Vendor Request", "Vendor Updated Successfully!")
         },
        onError: (error) => {
            setDisableBtn(false)
            showToast("error", "Vendor Request", error?.response?.data?.message)
        }
    })
}

// -------------------------Get Vendor By Id-------------------------//

const getVendorById = async (Id) => {
    const { data } = await axiosInstance.get(`/vendor/show/${Id}`);

    return data.data
}

export const useGetVendorById = () => {
    const { showToast, setDisableBtn } = useAdminGlobalContext();
  
    return useMutation(getVendorById, {
        onSuccess: async (data) => {
            setDisableBtn(false)
         },
        onError: (error) => {
            setDisableBtn(false)
            showToast("error", "Vendor details", error?.response?.data?.message)
        }
    })
}
