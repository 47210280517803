import React, { useEffect, useMemo, useState } from "react";
import { Carousel } from "react-responsive-3d-carousel";
import { useTranslation } from "react-i18next";
import serviceAnimation from "../../../../assets/img/animation/service/2- Services/Swap/swap2.gif";
import luxury_events from "../../../../assets/img/pages/services/luxury_events.png";
import luxury_agency from "../../../../assets/img/pages/services/luxury_agency.png";
import creative_hub from "../../../../assets/img/pages/services/creative_hub.png";
import merlin_ksa from "../../../../assets/img/pages/services/merlin_ksa.png";
import igual from "../../../../assets/img/pages/services/igual.jpg";
import xy from "../../../../assets/img/pages/services/xy.png";

const _images = [
  creative_hub,
  luxury_events,
  luxury_agency,
  xy,
  igual,
  merlin_ksa,
];

const Card = ({ index, list }) => {
  const { t } = useTranslation("home");

  return (
    <div key={index} className="carousel_card">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col fw-normal normal-case p-3 md:p-4 ">
          <div className="flex flex-row md:ml-10 d-flex align-items-center mb-2 mb-sm-8">
            <img
              className={`h-[40px] ${index - 1 === 4 ? "sm:h-[55px]" : "sm:h-[50px]"} me-1 me-sm-4`}
              src={_images[index - 1]}
              alt="Card"
            />
            <h1 className="text-black text-sm sm:text-2xl text-center font-[1000]">
              {t(`services.cards.card_${index}.title`)}
            </h1>
          </div>
          <p className="text-[14px] lg:text-[16px] text-black text-left md:ml-8 text-base ">
            {t(`services.cards.card_${index}.description`)}
          </p>
          <ul className="mt-3 text-base text-[14px] lg:text-[16px] md:ml-8 text-left">
            {list?.map((item, i) => {
              return (
                <li key={i} className="text-black">
                  {i + 1}. {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default function Services() {
  const { t, i18n } = useTranslation("home");
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const generateCard = () => {
    const newCards = [];

    for (let i = 1; i < 7; i++) {
      let list = t(`services.cards.card_${1}.list`, { returnObjects: true }) ?? [];
      if (!Array.isArray(list)) {
        continue;
      }
      newCards.push(<Card key={i} index={i} list={list} />);
    }

    return newCards;
  };

  useEffect(() => {
    generateCard();
  }, [i18n.language]);

  useMemo(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="services_section relative">
      <h1 className="text-[150%] sm:text-2xl md:text-3xl lg:text-5xl font-bold text-center mt-[80px] px-2 px-md-5 mb-md-5">
        {t("services.title")} &nbsp;
        <span className="text-yellow ">{t("services.title-color")}</span>
      </h1>
      <div className="position-relative w-100 flex justify-center align-center mb-[80px] ">
        {i18n.language && (
          <Carousel
            autoPlay={true}
            depth={6}
            spread="narrow"
            height={windowSize < 500 ? "500px" : "400px"}
            width={
              windowSize < 400 ? "310px" : windowSize < 600 ? "380px" : "600px"
            }
            showStatus={false}
            showArrows={false}
            numberOfSlides={5}
            startIndex={3}
            indicatorsActiveColor="rgb(238, 255, 41)"
            indicatorsSize="medium"
          >
            {generateCard()}
          </Carousel>
        )}
      </div>
      <div className="absolute md:w-[10%] lg:w-[7%] xl:right-[15%] lg:right-[8%] md:right-[3%] bottom-[25%] hide-on-tablet ">
        <img src={serviceAnimation} alt="" />
      </div>
    </div>
  );
}
