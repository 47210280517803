
import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { Toast } from 'primereact/toast';
import avatar from '../../../assets/img/avatar.webp';

const Global = createContext({});

export const AdminGlobalProvider = (props) => {
    const toastRef = useRef(null);

    const [disableBtn, setDisableBtn] = useState(false);

    // Devices 
    const [selectedDevice, setSelectedDevice] = useState("");

    //Rerender parent passed on child components updates
    const [rerender, setRerender] = useState(false);

    // Profile Image
    const [profileImage, setProfileImage] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));

    // Global Dialogs Controls
    const [openDialog, setOpenDialog] = useState({
        createEvent: false,
        editEvent: false,

        pdfViewer:false,
    });

    const dialogHandler = (key) => {
        setOpenDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    // Global Loader Handler for Single Inputs Editing
    const [subLoaders, setSubLoaders] = useState({

    });

    const loadingHandler = (key) => {
        setSubLoaders(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    // Global Dialogs Controls 
    const [deleteDialog, setDeleteDialog] = useState({
        event: false,
    });

    const deleteDialogHandler = (key) => {
        setDeleteDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const showToast = (severity, summary, detail, content = null) => {
        if (content == null)
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, life: 3000 });
        else
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, content: content, sticky: true });

    };

    return (
        <Global.Provider value={ {
            disableBtn, setDisableBtn,
            rerender, setRerender,

            profileImage, setProfileImage,

            openDialog, dialogHandler,
            subLoaders, loadingHandler,
            deleteDialog, deleteDialogHandler,

            selectedDevice, setSelectedDevice,

            showToast
        } }>
            <Toast ref={ toastRef } position="bottom-center"></Toast>
            { props.children }
        </Global.Provider>
    )
}

export const useAdminGlobalContext = () => {
    return useContext(Global)
}