import React from 'react'
import { Image } from 'react-bootstrap'

import notFound from "../../assets/img/404.png"
import { useTitle } from '../../config/global';
import Layout from './Layout/Layout'

function NotFound () {
  useTitle('Page Not found');

  return (
    <Layout>
      <div
        className="relative w-100 h-[100%] packages_container d-flex justify-center align-items-center " style={ { minHeight: `100vh` } }>
        <div className="d-flex flex-column-reverse flex-md-row justify-center align-items-center px-md-4 mx-3 mx-lg-5">
          <div className="col-md-6 col-10 px-md-5">
            <div className="text-[40px] md:text-[80px] fw-bolder text-yellow">Ooops...</div>
            <div className="text-[20px] md:text-[40px]">Page not found</div>
            <p className="text-[16px] md:text-[18px]">The page you are looking for doesn’t exist or an other error occurred!</p>
          </div>
          <div className="col-md-6 col-10">
            <Image src={ notFound }  className="mx-auto"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound