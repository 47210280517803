import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';
import { getFormErrorMessage } from '../../../../config/global';
import { chooseOptions } from '../../../../config/constants';

export default function Step3 ({ errors, control, setValue }) {
  const { fileFormHandler, activeStep } = useClientGlobalContext();
  const memberFileRef = useRef(null)
  const activityLicenseRef = useRef(null)
  const zakatFileRef = useRef(null)
  const { t } = useTranslation("vendor");

  // Cancel selected file 
  const onCancelUpload = (key) => {
    fileFormHandler(key, null)
    setValue([key]?.attachment, null, { shouldValidate: true });

    if (key === "chamber_membership_attachment")
      memberFileRef.current.clear();
    else if (key === "zakat_attachment")
      zakatFileRef.current.clear();
    else
      activityLicenseRef.current.clear();
  };

  const handleFileUpload = (e, onChange, key) => {
    const file = e.files[0];
    fileFormHandler(key, file)
    onChange(file);
    setValue([key]?.["attachment"], file, { shouldValidate: true });
  };

  return (
    <>
      <h1 className='h4 mb-3'>{ t('step3.title') }</h1>
      {/* Riyadh Chamber Member */ }
      <div className="h6 col-12 fw-bold mb-2">{ t('step2.riyadh_chamber_member') }</div>
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="riyadh_chamber_membership[number]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('number', errors, "riyadh_chamber_membership") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="riyadh_chamber_membership[validity]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Calendar
                id={ field.name }
                { ...field }
                inputRef={ field.ref }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('validity', errors, "riyadh_chamber_membership") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="chamber_membership_attachment" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ onChange, field, fieldState }) => (
              <FileUpload
                id={ field.name }
                { ...field }
                mode="basic"
                name="chamber_membership_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                inputRef={ field.ref }
                ref={ memberFileRef }
                onSelect={ (e) => handleFileUpload(e, onChange, "chamber_membership_attachment") }
                onClick={ () => onCancelUpload("chamber_membership_attachment") }
                chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('chamber_membership_attachment', errors) }
      </div>

      {/* commercial_activity_license */ }
      <div className="h6 col-12 fw-bold mb-2">{ t('step2.comm_activity_license') }</div>
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="commercial_activity_license[number]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('number', errors, "commercial_activity_license") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="commercial_activity_license[validity]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Calendar
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('validity', errors, "commercial_activity_license") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="activity_license_attachment" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ onChange, field, fieldState }) => (
              <FileUpload
                id={ field.name }
                { ...field }
                inputRef={ field.ref }
                mode="basic"
                name="activity_license_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                ref={ activityLicenseRef }
                onSelect={ (e) => handleFileUpload(e, onChange, "activity_license_attachment") }
                onClick={ () => onCancelUpload("activity_license_attachment") }
                chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('activity_license_attachment', errors) }
      </div>

      {/* zakat */ }
      <div className="h6 col-12 fw-bold mb-2">{ t('step2.zakat') }</div>
      <div className="field col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="zakat_tax_customs_authority_certificate[number]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
        </span>
        { getFormErrorMessage('number', errors, "zakat_tax_customs_authority_certificate") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="zakat_tax_customs_authority_certificate[validity]" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Calendar
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('validity', errors, "zakat_tax_customs_authority_certificate") }
      </div>

      <div className="field mb-3 col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
        <span className="p-float-label mb-1 me-md-3">
          <Controller name="zakat_attachment" control={ control }
            rules={ { required: activeStep >= 2 ? 'This field is required.' : false } }
            render={ ({ onChange, field, fieldState }) => (
              <FileUpload
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                mode="basic"
                name="zakat_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                ref={ zakatFileRef }
                onSelect={ (e) => handleFileUpload(e, onChange, "zakat_attachment") }
                onClick={ () => onCancelUpload("zakat_attachment") }
                chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('zakat_attachment', errors) }
      </div>
    </>
  )
}

