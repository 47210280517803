
import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import avatar from '../../../assets/img/avatar.webp';
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner";

const Global = createContext({});

export const ClientGlobalProvider = (props) => {
    const { i18n } = useTranslation("menu");
    const toastRef = useRef(null);

    const [activeMenuTab, setActiveMenuTab] = useState("home");
    const [eventDetails,setEventDetails]=useState({})
    const [vendorFormFiles, setVendorFormFiles] = useState({
        activity_license_attachment: null,
        chamber_membership_attachment: null,
        commercial_registration_attachment: null,
        company_profile_attachment: null,
        bank_certification_attachment: null,
        address_attachment: null,
        zakat_attachment: null,
        gosi_attachment: null,
        vat_attachment: null,
    });
    const [activeStep, setActiveStep] = useState(0);
    const [vendorType, setVendorType] = useState("local");

    const [disableBtn, setDisableBtn] = useState(false);
    const [didMount, setDidMount] = useState(false);

    //Rerender parent passed on child components updates
    const [rerender, setRerender] = useState(false);

    // Profile Image
    const [profileImage, setProfileImage] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));

    // Global Dialogs Controls
    const [openDialog, setOpenDialog] = useState({

    });

    const dialogHandler = (key) => {
        setOpenDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    // Global Loader Handler for Single Inputs Editing
    const [subLoaders, setSubLoaders] = useState({

    });

    const loadingHandler = (key) => {
        setSubLoaders(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    // Global Dialogs Controls 
    const [deleteDialog, setDeleteDialog] = useState({

    });

    const deleteDialogHandler = (key) => {
        setDeleteDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const fileFormHandler = (key, value) => {
        setVendorFormFiles(prev => ({
            ...prev, [key]: value
        }))
    }

    const showToast = (severity, summary, detail, content = null) => {
        if (content == null)
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, life: 3000 });
        else
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, content: content, sticky: true });

    };

    useEffect(() => {
        const loadStyle = () => {
            const lang = i18n.language === "ar" ? "ar" : "en";
            const styleSheet = document.createElement("link");
            styleSheet.href = `${process.env.PUBLIC_URL}/css/${ lang }/index-${ lang }.css?v=${ Date.now() }`;
            styleSheet.rel = "stylesheet";
            styleSheet.onload = () => {
                setDidMount(true);
            };
            document.head.appendChild(styleSheet);

            return () => {
                document.head.removeChild(styleSheet);
            };
        };

        document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
        setDidMount(false);
        loadStyle();
    }, [i18n.language]);

    return (
        <Global.Provider value={ {
            disableBtn, setDisableBtn,
            rerender, setRerender,

            profileImage, setProfileImage,

            openDialog, dialogHandler,
            subLoaders, loadingHandler,
            deleteDialog, deleteDialogHandler,

            vendorFormFiles, setVendorFormFiles, fileFormHandler,
            activeMenuTab, setActiveMenuTab,
            activeStep, setActiveStep,
            vendorType, setVendorType,
            showToast, eventDetails, setEventDetails
        } }>
            <Toast ref={ toastRef } position="bottom-center"></Toast>
            {
                didMount ?
                props.children
                :
                <div className="flex justify-center items-center h-100">
                    <ProgressSpinner className="w-[60px] height-[60px] opacity-10" strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                </div>
            }
        </Global.Provider>
    )
}

export const useClientGlobalContext = () => {
    return useContext(Global)
}