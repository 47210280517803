import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Image } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import logo from "../../assets/img/logo_black.png"
import { useGetTicketMutation } from './Queries'

function Ticket () {
  const getTicketInfo = useGetTicketMutation()
  let query = new URLSearchParams(useLocation().search);

  const [ticketData, setTicketData] = useState();

  const getData = async () => {
    const id = query.get('id');

    await getTicketInfo.mutateAsync({ id: id }, {
      onSuccess: (data) => { setTicketData(data[0]) }
    })
  }

  useEffect(() => {
    getData()

    return () => { }
  }, [])

  return (
    <div className="w-[100vw] h-[100vh] d-flex flex-column items-center justify-center bg-white">
      <Image src={ logo } className="mb-4"/>
      <div className="flex flex-column bg-[#DDE2E7] w-[94%] sm:w-[30%]  rounded-2xl" >
        <div className="flex flex-column bg-[#DDE2E7] p-4 rounded-t-xl ticket_card ">
          <h3 className="h4 mb-4 text-black">Ticket Details</h3>
          <div className="col-12 mb-2">
            <span className="fw-bold fs-10 text-black text-capitalize">Event Name:&nbsp;</span>
            <span className=" text-black text-capitalize"> { ticketData?.name_en }</span>
          </div>
          <div className="col-12 mb-2">
            <span className="fw-bold  text-black">Event Location:&nbsp;</span>
            <span className="text-black"> { ticketData?.location }</span>
          </div>
          <div className="col-12 mb-2">
            <span className="fw-bold  text-black">Date:&nbsp;</span>
            <span className="text-black"> { ticketData?.date }</span>
          </div>
          <div className="col-12 mb-2">
            <span className="fw-bold text-black">Time:&nbsp;</span>
            <span className="text-black text-capitalize">{ ticketData?.time.toLowerCase().replace("_", " ") }</span>
          </div>
          <div className="col-12 mb-2">
            <span className="fw-bold text-black">Seating:&nbsp;</span>
            <span className="text-black ">{ ticketData?.area }</span>
          </div>
        </div>
        <div className="py-2 bg-white ">
          <div class="border-t-2 border-secondary border-dashed"></div>
        </div>

        <div className="bg-[#DDE2E7]  p-4 rounded-b-xl ticket_card">
          <div className="h4 fw-bold text-black"> TicKet QR code</div>
          <div className="text-center">
            <Image src={ ticketData?.qr_code } alt='QR-code' className='mx-auto my-3' />
            <p className="fw-bold text-black">Scan on entrance</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ticket