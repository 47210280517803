import React from 'react'
import { Image } from 'react-bootstrap'

import comingSoon from '../../../assets/img/new_coming_soon.png'
import comingSoonAr from '../../../assets/img/coming_soon_ar.png'
import animation from "../../../assets/img/animation/coming_soon/Coming Soon/Waiting.gif"

import Layout from '../Layout/Layout'
import { useTranslation } from 'react-i18next'
import { useTitle } from '../../../config/global'

function Packages () {
  useTitle('Packages');
  const { i18n } = useTranslation();

  return (
    <Layout>
      <div className="relative w-100 h-[100%] packages_container d-flex justify-center align-items-center px-5" style={ { minHeight: `90vh` } }>
        <Image src={ i18n?.language === "en" ? comingSoon : comingSoonAr } />
        <img className={ `absolute w-[10%] top-[50%] ${ i18n?.language === "en" ? "left-[80%] " : "right-[80%] " }` } src={ animation } alt="" />
      </div>
    </Layout>
  )
}

export default Packages