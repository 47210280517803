import React, { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import { useClientGlobalContext } from '../../Contexts/ClientGlobalContext';
import { getFormErrorMessage } from '../../../../config/global';
import { chooseOptions } from '../../../../config/constants';

export default function Step2 ({ errors, control, setValue }) {
  const { fileFormHandler, activeStep, vendorType } = useClientGlobalContext();
  const vatFileRef = useRef(null)
  const gosiFileRef = useRef(null)
  const commercialFileRef = useRef(null)

  const { t } = useTranslation("vendor");

  // Cancel selected file 
  const onCancelUpload = (key) => {
    fileFormHandler(key, null)
    setValue(key, null, { shouldValidate: true });

    if (key === "vat")
      vatFileRef.current.clear();
    else if (key === "gosi")
      vatFileRef.current.clear();
    else
      commercialFileRef.current.clear();
  };

  const handleFileUpload = (e, onChange, key) => {
    const file = e.files[0];
    fileFormHandler(key, file)
    onChange(file);
    setValue(key, file, { shouldValidate: true });
  };


  return (
    <>
      <h1 className='h4 mb-3'>{ t('step2.title') }</h1>
      {/* Commercial Information */ }
      <div className="h6 col-12 fw-bold mb-2">{ t('step2.com_reg') }</div>

      <div className="field  col-12 col-md-6 mb-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller name="commercial_registration[number]" control={ control }
            rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <input
                type="number"
                id={ field.name }
                inputRef={ field.ref }
                { ...field }
                className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('number', errors, "commercial_registration") }
      </div>

      <div className="field mb-3  col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller name="commercial_registration[validity]" control={ control }
            rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
            render={ ({ field, fieldState }) => (
              <Calendar
                id={ field.name }
                { ...field }
                inputRef={ field.ref }
                className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
            ) } />
        </span>
        { getFormErrorMessage('validity', errors, "commercial_registration") }
      </div>

      <div className="field mb-3  col-12 col-md-3">
        <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
        <span className="p-float-label mb-1 pe-md-3">
          <Controller name="commercial_registration_attachment" control={ control }
            rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
            render={ ({ onChange, field, fieldState }) => (
              <FileUpload
                id={ field.name }
                { ...field }
                inputRef={ field.ref }
                mode="basic"
                name="commercial_registration_attachment"
                accept=".pdf"
                maxFileSize={ 1000000000 }
                customUpload
                ref={ commercialFileRef }
                onSelect={ (e) => handleFileUpload(e, onChange, "commercial_registration_attachment") }
                onClick={ () => onCancelUpload("commercial_registration_attachment") }
                chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                icon="none"
              />
            ) } />
        </span>
        { getFormErrorMessage('commercial_registration_attachment', errors) }
      </div>
      { vendorType === "local" ? <>
        {/* Vat */ }
        <div className="h6 col-12 fw-bold mb-2">{ t('step2.vat') }</div>
        <div className="field  col-12 col-md-6 mb-3">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="vat[number]" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
              render={ ({ field, fieldState }) => (
                <input
                  type="number"
                  id={ field.name }
                  inputRef={ field.ref }
                  { ...field }
                  className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
          </span>
          { getFormErrorMessage('number', errors, "vat") }
        </div>

        <div className="field mb-3  col-12 col-md-3">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="vat[validity]" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
              render={ ({ field, fieldState }) => (
                <Calendar
                  id={ field.name }
                  { ...field }
                  inputRef={ field.ref }

                  className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
              ) } />
          </span>
          { getFormErrorMessage('validity', errors, "vat") }
        </div>

        <div className="field mb-3  col-12 col-md-3">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="vat_attachment" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field input is required.' : false } }
              render={ ({ onChange, field, fieldState }) => (
                <FileUpload
                  id={ field.name }
                  inputRef={ field.ref }
                  { ...field }
                  mode="basic"
                  name="vat_attachment"
                  accept=".pdf"
                  maxFileSize={ 1000000000 }
                  customUpload
                  ref={ vatFileRef }
                  onSelect={ (e) => handleFileUpload(e, onChange, "vat_attachment") }
                  onClick={ () => onCancelUpload("vat_attachment") }
                  chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                  className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                  icon="none"
                />
              ) } />
          </span>
          { getFormErrorMessage('vat_attachment', errors) }
        </div>

        {/* gosi */ }
        <div className="h6 col-12 fw-bold mb-2">{ t('step2.gosi') }</div>
        <div className="field  col-12 col-md-6 mb-3 ">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.number') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="gosi[number]" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
              render={ ({ field, fieldState }) => (
                <input
                  type="number"
                  id={ field.name }
                  inputRef={ field.ref }
                  { ...field }
                  className={ `p-inputtext p-component input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />) } />
          </span>
          { getFormErrorMessage('number', errors, 'gosi') }
        </div>

        <div className="field mb-3  col-12 col-md-3">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.validity') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="gosi[validity]" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
              render={ ({ field, fieldState }) => (
                <Calendar
                  id={ field.name }
                  { ...field }
                  inputRef={ field.ref }
                  className={ `input-3d-shadow rounded-[20px] w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
              ) } />
          </span>
          { getFormErrorMessage('validity', errors, 'gosi') }
        </div>

        <div className="field mb-3  col-12 col-md-3">
          <label className="mb-1 text-capitalize text-[13px]">{ t('step2.attachment') }</label>
          <span className="p-float-label mb-1 pe-md-3">
            <Controller name="gosi_attachment" control={ control }
              rules={ { required: activeStep >= 1 ? 'This field is required.' : false } }
              render={ ({ onChange, field, fieldState }) => (
                <FileUpload
                  id={ field.name }
                  { ...field }
                  inputRef={ field.ref }
                  mode="basic"
                  name="gosi_attachment"
                  accept=".pdf"
                  maxFileSize={ 1000000000 }
                  customUpload
                  ref={ gosiFileRef }
                  onSelect={ (e) => handleFileUpload(e, onChange, "gosi_attachment") }
                  onClick={ () => onCancelUpload("gosi_attachment") }
                  chooseOptions={ { ...chooseOptions, style: { ...chooseOptions?.style, fontSize: "13px" }, label: t('step2.upload') } }
                  className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                  icon="none"
                />
              ) } />
          </span>
          { getFormErrorMessage('gosi_attachment', errors) }
        </div>
      </> : <></> }
    </>
  )
}

