import React from 'react';
import { useLogOutMutation } from '../Queries';

const Logout = ({ classes = "" }) => {
 
    const logout = useLogOutMutation();

    const handleClick = async() => {
        await logout.mutateAsync();
    }

    return (

        <button className={ `dropdown-item ${ classes != "" ? classes : "" }` }  onClick={ handleClick }>
            <i className="bx bx-power-off me-2" />
            <span className="align-middle">Log Out</span>
        </button>
    );
}

export default Logout;