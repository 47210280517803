import axios from "axios";

export const uploadFile = async (file, name) => {

  return new Promise(async (resolve, reject) => {
    const bucketName = "luxuryksa";
    const apiBaseUrl =  "https://lf0rega465.execute-api.eu-west-1.amazonaws.com/Pre-signedUrlgenerator";
    const key = `${Date.now()}${name.replace(/\s/g, "")}`;
    console.log(name,key);
    const apiUrl = `${apiBaseUrl}?file_name=${key}&bucket_name=${bucketName}`;
    const s3Url = `https://luxuryksa-file.livaatverse.com/${key}`;

    try {
      const response = await axios.post(apiUrl);
      const presignedPostData = response.data;
      const formData = new FormData();
      
      Object.keys(presignedPostData.fields).forEach((key) => {
        console.log(key, presignedPostData.fields[key]);
        formData.append(key, presignedPostData.fields[key]);
      });
      
      formData.append("file", file); 
      

      const uploadResponse = await axios
        .post(presignedPostData.url, formData, {
          onUploadProgress: (evt) => {},
        })
        .then((result) => console.log(result, "result"))
        .catch((error) => console.log(error?.response));
      resolve(s3Url);
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      reject(error);
    }
  });
};
