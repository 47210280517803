import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Layout from "../Layout/Layout";

import { useClientGlobalContext } from "../Contexts/ClientGlobalContext";
import { useBookEvents, useGetEventById } from "../Queries/event";
import { useTitle } from "../../../config/global";

function BoxOfficePayment () {
  useTitle('Box-Offices');

  const { id } = useParams();
  const { data: eventByID } = useGetEventById(id);
  const { t, i18n } = useTranslation("box_offices");
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const steps = [{ label: "Payment" }, { label: "Payment Details" }];
  const { activeStep, setActiveStep } = useClientGlobalContext();
  const bookEvents = useBookEvents();

  const validateStepData = () => {
    setActiveStep((prev) => prev + 1);
  };

  const goBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const onSubmit = async (values) => {
    try {
      await bookEvents.mutateAsync(
        {
          ...values,
          event_id: id,
          time:
            values.time.toLowerCase() === "day" ? "DAY_EVENTS" : "NIGHT_EVENTS",
        },
        {
          onSuccess: () => {
            setActiveStep((prev) => prev + 1);
            reset();
          },
        }
      );
    } catch (error) {
      console.error(error);


    }
  };

  return (
    <Layout>
      <section className="d-flex flex-column py-8 antialiased md:py-0 h-100 ">
        <div className="col-12 rounded-t-3xl absolute h-[130vh] z-[0]">
          <div className=" w-100  h-50 "
            style={ {
              backgroundImage: `url(${ eventByID?.poster })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            } }>
          </div>
        </div>

        <div className=" col-12 col-lg-9 z-[1] rounded-[15px] book-form-container mt-[150px] md:mt-[250px]  mx-md-auto mb-[100px] p-md-5 p-3 " >
          <div>
            <h1 className="text-start my-4 text-4xl md:text-5xl lg:text-[330%] text-yellow  mt-0">
              { i18n.language == "ar" ? eventByID?.name_ar : eventByID?.name_en }
            </h1>
            <div className="flex flex-md-row flex-column items-start flex-wrap">
              <div className="col-md-6 col-12 mb-3 pe-5">
                <i className="fa-2x far fa-calendar-alt mr-3"></i>
                <span className=" text-xl text-gray-900 dark:text-gray-400 ">
                  { eventByID?.start_date }{ " " }
                  <span className="font-extrabold">TO</span> { eventByID?.end_date }
                </span>
              </div>
              <div className="col-md-6 col-12 ps-md-3">
                <i className="fa-2x fas fa-map-marker-alt mr-3"></i>
                <span className=" text-xl text-gray-900 dark:text-gray-400">
                  { eventByID?.location }
                </span>
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-bold mb-3 mt-5 text-gray-900 dark:text-white sm:text-3xl">
            { activeStep >= 2 ? (
              <></>
            ) : (
              <>{ t("second-page.Book-Ticket") }</>
            ) }
          </h2>
          <form
            className="d-flex flex-column w-100 rounded-lg dark:border-gray-700 dark:bg-gray-800"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <div className="col-12 d-flex  flex-column flex-md-row">
              { activeStep === 0 ? (
                <Step1
                  eventDetails={ eventByID }
                  errors={ errors }
                  control={ control }
                  setValue={ setValue }
                />
              ) : (
                <></>
              ) }
              { activeStep === 1 ? (
                <Step2
                  eventDetails={ eventByID }
                  errors={ errors }
                  control={ control }
                  setValue={ setValue }
                />
              ) : (
                <></>
              ) }
              { activeStep === 2 ? <Step3 /> : <></> }
            </div>
            <div
              className={ `col-12 flex flex-row align-end flex-nowrap md:mt-5 mt-3 md:justify-end  justify-start${ activeStep > 0 ? "justify-end" : ""
                }` }
            >
              {/* {
                          activeStep > 0 && activeStep === 1 ? (
                          <button
                            onClick={ goBack }
                            type="button"
                            className="col-lg-2 items-center text-capitalize justify-center rounded-xl px-5 py-2 me-3 text-sm font-bold text-white hover:bg-primary-800 focus:outline-none focus:ring-4 border-2 border-white focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          >
                            { t("teard-page.back") }
                          </button>
                        ) : (
                          <></>
                        ) } */}

              { activeStep === steps?.length - 1 && (
                <button
                  onClick={ handleSubmit(validateStepData) }
                  // type="submit"
                  className="col-lg-2 submit_btn flex items-center justify-center rounded-lg bg-white px-5 py-3 text-sm font-bold text-black hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Submit
                </button>
              ) }

              { activeStep === 0 ? (
                <button
                  type="button"
                  onClick={ handleSubmit(validateStepData) }
                  className={ `${ activeStep === 0 ? "col-12 col-md-6" : ""
                    } flex items-center justify-center rounded-lg bg-white px-5 py-3 text-sm font-bold text-black hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800` }
                >
                  { t("second-page.ckeckout") }
                </button>
              ) : (
                <></>
              ) }
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
}

export default BoxOfficePayment;
