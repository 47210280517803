import React, { Suspense, useEffect, useState, useTransition } from "react";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import moment from "moment";
import { useClientGlobalContext } from "../../Contexts/ClientGlobalContext";
import { Dropdown } from "primereact/dropdown";
import { scopeOfWorkOptions } from "../../../../config/constants";
import { classNames } from "primereact/utils";
import { getFormErrorMessage } from "../../../../config/global";
import mapURL from "../../../../assets/img/Glb_Map.glb";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link, useParams } from "react-router-dom";
import { values } from "lodash";

const materialNames = [
  "BeachArea",
  "PoolArea",
  "BeachConcert(DayConcet)",
  "Gold",
  "Standing",
  "VIP-Lounge",
];
const timesAreas = {
  day: ["BeachArea", "PoolArea", "BeachConcert(DayConcet)"],
  night: ["Gold", "Standing", "VIP-Lounge"],
};
const timeOptions = [
  { label: "Day", value: "day" },
  { label: "Night", value: "night" },
];

const areasOptions = {
  day: [
    { label: "BeachArea", value: "BeachArea" },
    { label: "PoolArea", value: "PoolArea" },
    { label: "BeachConcert(DayConcert)", value: "BeachConcert(DayConcet)" },
  ],
  night: [
    { label: "Gold", value: "Gold" },
    { label: "Standing", value: "Standing" },
    { label: "VIP-Lounge", value: "VIP-Lounge" },
  ],
};

function Step1({ errors, control, eventDetails }) {
  const { t, i18n } = useTranslation("box_offices");
  const { activeStep,dateChoosen,setDateChoosen } = useClientGlobalContext();
  const { id } = useParams();
  const [timeChoosen, setTimeChoosen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedTime, setSelectedTime] = useState();
  const [originColors, setOriginColors] = useState({});
  const [isPending, startTransition] = useTransition();
  const [dateOptions, setDateOptions] = useState([]);
  const activeAreaColor = new THREE.Color("rgb(238, 255, 41)");
  const inactiveAreaColor = new THREE.Color("#c6c6c6");
  const { scene, materials } = useGLTF(mapURL);
  useEffect(() => {
    let _originColors = {};
    for (const key in materials) {
      if (materialNames.includes(key)) {
        _originColors[key] = new THREE.Color(materials?.[key]?.color);
      }
    }
    setOriginColors(_originColors);
  }, []);

  const handleDropdownChange = (value) => {
    startTransition(() => {
      materials?.[selectedMaterial]?.color.set(inactiveAreaColor);
      setSelectedMaterial(value);
    });
  };

  const handleTimeDropdownChange = (value) => {
    if (selectedTime) {
      timesAreas?.[selectedTime].map((area) => {
        materials?.[area]?.color.set(inactiveAreaColor);
      });
    }
    materials?.[selectedMaterial]?.color.set(inactiveAreaColor);
    setSelectedTime(value);
  };

  useEffect(() => {
    startTransition(() => {
      if (selectedMaterial) {
        timesAreas?.[selectedTime].map((area) => {
          if (area !== selectedMaterial)
            materials?.[area]?.color.set(inactiveAreaColor);
        });

        materials?.[selectedMaterial]?.color.set(activeAreaColor);
      }
    });
  }, [selectedMaterial]);

  useEffect(() => {
    startTransition(() => {
      if (selectedTime) {
        timesAreas?.[selectedTime].map((area) => {
          materials?.[area]?.color.set(activeAreaColor);
        });

        let _rest = selectedTime === "night" ? "day" : "night";
        timesAreas?.[_rest].map((area) => {
          materials?.[area]?.color.set(inactiveAreaColor);
        });
      }
    });
  }, [selectedTime]);

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");

    const startDate = new Date(eventDetails?.start_date);
    const endDate = new Date(eventDetails?.end_date);
    const options = [];

    for (
      let date = moment(startDate).add(1, "days");
      date <= moment(endDate);
      date.add(1, "days")
    ) {
      if (date.isAfter(currentDate)) {
        options.push({
          label: date.format("YYYY-MM-DD"),
          value: date.format("YYYY-MM-DD"),
        });
      }
    }
    setDateOptions(options);
  }, [eventDetails]);
console.log(dateChoosen,"ggggggggggggggggggg")
  return (
    <>
      <div className="col-12 col-md-6  ">
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label
            htmlFor="choose_date"
            className="mb-2 block text-xl text-gray-900 dark:text-white"
          >
            {t("second-page.Choose-Date")}
          </label>

          <Controller
            name="date"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 0
                    ? "choose date is required"
                    : false
                  : activeStep === 0
                  ? "اختيار التاريخ مطلوب"
                  : false,
            }}
            render={({ field, fieldState }) => (
              <Dropdown
                id={field.name}
                {...field}
                inputRef={field.ref}
                onChange={(e) => {field.onChange(e.value)
                  setDateChoosen(e.value)
                }}
                options={dateOptions}
                optionLabel="label"
                optionValue="value"
                /*rod border on error */
                className={`bg-gradient-to-r from-[#ffffff31] to-black w-100 input-3d-shadow ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("date", errors)}
        </div>

        <div className="col-span-2 sm:col-span-1 mb-4">
          <label
            htmlFor="choose_time"
            className="mb-2 block text-xl text-gray-900 dark:text-white"
          >
            {t("second-page.Choose-Time")}
          </label>
          <Controller
            name="time"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 0
                    ? "choose time is required"
                    : false
                  : activeStep === 0
                  ? "اختيار الوقت مطلوب"
                  : false,
            }}
            render={({ field, fieldState }) => (
              <Dropdown
                id={field.name}
                {...field}
                inputRef={field.ref}
                value={selectedTime}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleTimeDropdownChange(e.value);
                  setTimeChoosen(true);
                }}
                options={timeOptions}
                optionLabel="label"
                optionValue="value"
                /*rod border on error */
                className={`$timeChoosen? bg-gradient-to-r from-[#ffffff31] to-black w-100 input-3d-shadow ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("time", errors)}
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label
            htmlFor="choose_seating"
            className={`mb-2 block text-xl ${
              timeChoosen ? "font-normal" : "font-thin"
            }`}
          >
            {t("second-page.Choose-Seating-Area")}
          </label>
          <Controller
            name="area"
            control={control}
            rules={{
              required:
                i18n.language === "en"
                  ? activeStep === 0
                    ? "choose seating is required"
                    : false
                  : activeStep === 0
                  ? "اختيار المقاعد مطلوب"
                  : false,
            }}
            render={({ field, fieldState }) => (
              <Dropdown
                disabled={timeChoosen ? false : true}
                id={field.name}
                {...field}
                inputRef={field.ref}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleDropdownChange(e.value);
                }}
                options={areasOptions?.[selectedTime]}
                optionLabel="label"
                optionValue="value"
                /*rod border on error */
                className={` bg-gradient-to-r from-[#ffffff31] to-black w-100 input-3d-shadow  ${classNames(
                  { "p-invalid": fieldState.invalid }
                )}`}
              />
            )}
          />
          {getFormErrorMessage("area", errors)}
        </div>
        <div className="flex flex-row justify-start items-start text-xl my-3">
          <span className="mr-10 font-bold">{t("second-page.Total")}</span>
          <span> {t("second-page.price")}</span>
        </div>
      </div>

      {/* ************************************************************** */}
      <div className="col-12 col-md-6 mt-6 sm:mt-8 ps-4 ">
        <Canvas
          className="md-"
          style={{ width: "100%", height: "100%" }}
          camera={{ position: [2, 9, 14], fov: 10, near: 0.15, far: 200 }}
        >
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 5, 5]} intensity={1} />
          <Suspense fallback={<Loading />}>
            <primitive object={scene} scale={5} style={{ width: "100%" }} />
          </Suspense>
          <OrbitControls />
        </Canvas>
      </div>
    </>
  );
}

export default Step1;

export const Loading = () => {
  return (
    <div className="flex justify-center items-center h-100">
      <ProgressSpinner
        className="w-[60px] height-[60px] opacity-10"
        strokeWidth="3"
        fill="var(--surface-ground)"
        animationDuration="1s"
      />
    </div>
  );
};
