import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { formatDuration, useTitle } from '../../../config/global';
import { useAdminGlobalContext } from '../Contexts/AdminGlobalContext';
import { useDeleteDataTableRow } from '../Queries';
import { useDataTableContext } from '../Contexts/DataTableContext';

import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import Layout from '../Layout/Layout';
import CreateDialog from './components/CreateDialog';
import EditDialog from './components/EditDialog';
import { Image } from 'react-bootstrap';


function EventsIndex () {
    useTitle('events');

    const { deleteDialog, deleteDialogHandler, openDialog, dialogHandler, } = useAdminGlobalContext();
    const { totalRecords, setReload, onPage, lazyParams, setLazyParams, data, dataHandler, getDataTable } = useDataTableContext();
    const [selectedEvent, setSelectedEvent] = useState({ name_en: "" });

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "events",
            filters: {
                'name_ar': { value: '', matchMode: 'contains' },
                'name_en': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Link to setup page */ }
                <Tooltip target={ `.setup-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <NavLink to={ `/admin/event/schedule/${rowData.id}` } >
                    <button className={ `btn btn-sm btn-icon setup-button-${ rowData.id }` }
                        data-pr-position="bottom" data-pr-tooltip="Setup">
                        <i className="fa-solid fa-eye"></i>
                    </button>
                </NavLink>
                
                {/* Update event */ }
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => editEvent(rowData) }><i className="pi pi-pencil"></i></button>
            </div>
        );
    }

    // Data Table Body Template
    const posterTemplate = (rowData) => {
        return (
            <Image src={ rowData?.poster } width="50" height="50" />
        );
    }
    //Dialog Handler
    const createEvent = () => {
        setSelectedEvent({});
        dialogHandler("createEvent");
    }

    const editEvent = (data) => {
        setSelectedEvent(data);
        dialogHandler("editEvent")
    }

    // const goToSetup = (eventID) => {
    //     navigate(`/events/${ eventID }/setup`)
    // }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2 fs-4">Events List</h5>
                    <button type="button" className="btn btn-primary me-sm-3 me-1 fw-bold" onClick={ createEvent }>
                        Create Event
                    </button>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable
                        lazy
                        paginator
                        rows={ lazyParams.rows }
                        value={ data }
                        first={ lazyParams.first }
                        onPage={ onPage }
                        onSort={ dataHandler }
                        filters={ lazyParams.filters }
                        dataKey="id"
                        loading={ getDataTable?.isLoading }
                        onFilter={ dataHandler }
                        sortField={ lazyParams.sortField }
                        className="table"
                        sortOrder={ lazyParams.sortOrder }
                        totalRecords={ totalRecords }
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                    >
                        <Column body={ posterTemplate } header="Poster" className='text-center' />
                        <Column field="name_en" header="Name Ar" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                        <Column field="name_ar" header="Name En" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                        <Column field="start_date" header="Start Date" className='text-center' sortable />
                        <Column field="end_date" header="End Date" className='text-center' sortable />
                        <Column field="location" header="Location" className='text-center' sortable />
                        <Column field="created_at" header="Created Date" className='text-center' sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>

                {
                    openDialog.createEvent ? <CreateDialog /> : <></>
                }
                {
                    openDialog.editEvent ? <EditDialog selectedEvent={ selectedEvent } /> : <></>
                }

            </div>
        </Layout>
    );
}

export default EventsIndex