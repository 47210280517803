import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { chooseOptions } from "../../../config/constants";
import { getFormErrorMessage } from "../../../config/global";
import Layout from "../Layout/Layout";
import { uploadFile } from "../../../config/UploadFolder";
import { useUploadVideoMutation } from "../Queries/setting";
import { useAdminGlobalContext } from "../Contexts/AdminGlobalContext";

export default function Setting() {
  const videoUpload = useUploadVideoMutation();
  const { showToast } = useAdminGlobalContext();

  const UploadVideoRef1 = useRef();
  const UploadVideoRef2 = useRef();
  const [fileForm1, setFileForm1] = useState(null);
  const [fileForm2, setFileForm2] = useState(null);
  const { formState: { errors }, handleSubmit, control, setValue } = useForm();

  // Cancel selected files
  const onCancelUpload1 = () => {
    setValue("Header-video-1", null, { shouldValidate: true });
    UploadVideoRef1.current.clear();
    setFileForm1(null);
  };

  const onCancelUpload2 = () => {
    setValue("Header-video-2", null, { shouldValidate: true });
    UploadVideoRef2.current.clear();
    setFileForm2(null);
  };

  const handleFileUpload1 = (e, onChange) => {
    const file = e.files[0];
    setFileForm1(file);
    setValue("Header-video-1", file, { shouldValidate: true });
  };

  const handleFileUpload2 = (e, onChange) => {
    const file = e.files[0];
    setFileForm2(file);
    setValue("Header-video-2", file, { shouldValidate: true });
  };

  const onSubmit = async (data) => {
    try {
      if (!fileForm1 || !fileForm2) {
        showToast("error", "Upload Error", "Please upload both video files.");
        return;
      }

      const res1 = await uploadFile(fileForm1, fileForm1.name);
      const res2 = await uploadFile(fileForm2, fileForm2.name);

      await videoUpload.mutateAsync({ home_video: res1, home_mobile_video: res2 });

      showToast("success", "Upload Successful", "Videos uploaded successfully.");
    } catch (error) {
      console.error("Error uploading videos:", error);
      showToast("error", "Upload Error", "Failed to upload videos.");
    }
  };

  return (
    <Layout>
      <div className="bg-[#171717] py-10 px-10 flex flex-col justify-start items-start rounded-t-xl">
        <h1 className="text-3xl mb-10">Upload Videos</h1>
        <form className="w-full" action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="field mb-3 col-12 col-md-6">
            <label className="mb-1 text-capitalize"> Upload Home Video </label>
            <span className="p-float-label mb-1 pe-3">
              <Controller
                name="Header-video-1"
                control={control}
                rules={{
                  required: !fileForm1 && "Video file 1 is required.",
                }}
                render={({ onChange, field, fieldState }) => (
                  <FileUpload
                    id={field.name}
                    {...field}
                    mode="basic"
                    name="Header-video-1"
                    accept=".mp4"
                    maxFileSize={1000000000}
                    customUpload
                    ref={UploadVideoRef1}
                    onSelect={(e) => handleFileUpload1(e, onChange)}
                    onRemove={onCancelUpload1} // Clear files on cancel
                    chooseOptions={{
                      ...chooseOptions,
                      label: "Upload Home Video ",
                    }}
                    className={`w-100 ${classNames({
                      "p-invalid": fieldState.invalid,
                    })}`}
                    icon="none"
                    inputRef={field.ref}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("Header-video-1", errors)}
          </div>

          <div className="field mb-3 col-12 col-md-6">
            <label className="mb-1 text-capitalize"> Upload Home Mobile Video </label>
            <span className="p-float-label mb-1 pe-3">
              <Controller
                name="Header-video-2"
                control={control}
                rules={{
                  required: !fileForm2 && "Video file 2 is required.",
                }}
                render={({ onChange, field, fieldState }) => (
                  <FileUpload
                    id={field.name}
                    {...field}
                    mode="basic"
                    name="Header-video-2"
                    accept=".mp4"
                    maxFileSize={1000000000}
                    customUpload
                    ref={UploadVideoRef2}
                    onSelect={(e) => handleFileUpload2(e, onChange)}
                    onRemove={onCancelUpload2} // Clear files on cancel
                    chooseOptions={{
                      ...chooseOptions,
                      label: "Upload Home Mobile Video ",
                    }}
                    className={`w-100 ${classNames({
                      "p-invalid": fieldState.invalid,
                    })}`}
                    icon="none"
                    inputRef={field.ref}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("Header-video-2", errors)}
          </div>

          <button type="submit" className="btn btn-primary mt-3">Submit</button>
        </form>
      </div>
    </Layout>
  );
}

